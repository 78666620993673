<template>
  <v-app-bar fixed height="100px;" color="white" light>
    <v-toolbar-title
      ><img
        style="max-width: 100%;"
        :src="logo"
        width="160px;"
        @click="$router.push('/')"
    /></v-toolbar-title>

    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <span
      style="font-size: 18px; margin-right: 10px"
      class="d-none d-sm-flex"
      v-if="$store.state.accessToken"
      >Hola, {{ $store.state.username }}</span
    >

    <v-menu offset-y v-if="$store.state.accessToken">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title
            @click="$router.push('/profile')"
            style="cursor: pointer"
            >Mi Perfil
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            @click="$router.push('/payments')"
            style="cursor: pointer"
            >Pagos en efectivo
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          ><v-list-item-title @click="logout()" style="cursor: pointer"
            >Salir</v-list-item-title
          ></v-list-item
        >
      </v-list>
    </v-menu>
    <v-btn icon @click="$router.push('/shop')" target="_blank">
      <v-icon>mdi-basket</v-icon>
      <div>Tienda</div>
    </v-btn>
    <v-btn
      icon
      @click="$router.push('/checkout')"
      style="margin-right: 30px"
      v-if="$store.state.accessToken"
    >
      <v-icon>mdi-cart</v-icon>
      <v-badge
        :content="$store.state.cart.length + $store.state.cartEcommerce.length + $store.state.cartCertifications.length"
        v-if="
          $store.state.cart.length > 0 || $store.state.cartEcommerce.length > 0 || $store.state.cartCertifications.length > 0
        "
      ></v-badge>
      <div>Carrito</div>
    </v-btn>
    <v-btn
      v-if="!$store.state.accessToken"
      style="cursor: pointer"
      color="purple"
      dark
      @click="show = true"
      >Iniciar sesión</v-btn
    >

    <v-dialog v-model="showRecovery" max-width="400">
      <v-card :loading="loginLoading" style="padding: 50px">
        <v-card-title class="text-h5" style="text-align: center"
          ><img
            style="margin-left: auto; margin-right: auto"
            :src="logo"
            width="200px;"
          />
        </v-card-title>

        <v-card-text>
          <div v-if="!recoverySent && !recoveryError">
            <v-text-field
              outlined
              label="Correo electrónico"
              v-model="usernameLogin"
              hide-details="auto"
            ></v-text-field>

            <v-btn
              color="purple"
              dark
              style="width: 100%; margin-bottom: 20px; margin-top: 20px"
              @click="sendRecovery()"
            >
              Enviar correo
            </v-btn>
          </div>
          <div v-if="recoverySent && !recoveryError">
            <p style="font-size: 20px">
              Hemos enviado un correo electrónico con tu nueva contraseña
            </p>
          </div>
          <div v-if="!recoverySent && recoveryError">
            <p style="font-size: 20px">
              No tenemos registrado el correo electrónico ingresado, por favor
              revisa el correo o crea una cuenta.
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show" max-width="400">
      <v-form ref="loginForm">
        <v-card :loading="loginLoading" style="padding: 50px">
          <v-card-title class="text-h5" style="text-align: center"
            ><img
              style="margin-left: auto; margin-right: auto"
              :src="logo"
              width="200px;"
            />
          </v-card-title>

          <v-card-text>
            <v-text-field
              outlined
              label="Correo electrónico"
              v-model="usernameLogin"
              :rules="[rules.required, rules.email]"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              name="input-10-2"
              value="wqfasds"
              class="input-group--focused"
              @click:append="show3 = !show3"
              outlined
              label="Contraseña"
              :rules="[rules.required]"
              v-model="passwordLogin"
              style="margin-top: 15px"
            ></v-text-field>
            <p style="cursor: pointer" @click="recovery" v-if="false">
              Olvide mi contraseña
            </p>
            <p style="color: red" v-if="errorLogin">
              Usuario o password incorrectos
            </p>
            <v-btn
              color="purple"
              dark
              style="width: 100%; margin-bottom: 20px"
              @click="validateLogin()"
            >
              Iniciar sesión
            </v-btn>
            <v-btn
              color="purple"
              dark
              style="width: 100%"
              @click="showRegisterModal()"
            >
              Aún no tengo cuenta
            </v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="showRegister" max-width="400">
      <v-card :loading="loginLoading" style="padding: 50px">
        <v-card-title class="text-h5" style="text-align: center"
          ><img
            style="margin-left: auto; margin-right: auto"
            :src="logo"
            width="200px;"
          />
        </v-card-title>
        <v-form ref="formRegister">
          <v-card-text>
            <v-text-field
              outlined
              label="Correo electrónico"
              v-model="usernameLogin"
              :rules="[rules.required, rules.email]"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              outlined
              label="Nombre"
              v-model="name"
              :rules="[rules.required]"
              hide-details="auto"
              style="margin-top: 15px"
            ></v-text-field>
            <v-text-field
              outlined
              label="Whatsapp (Opcional)"
              v-model="whatsapp"
              hide-details="auto"
              style="margin-top: 15px"
            ></v-text-field>
            <v-text-field
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              name="input-10-2"
              value="wqfasds"
              class="input-group--focused"
              @click:append="show3 = !show3"
              outlined
              label="Contraseña"
              v-model="passwordLogin"
              style="margin-top: 15px"
            ></v-text-field>
            <v-text-field
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              name="input-10-2"
              value="wqfasds"
              class="input-group--focused"
              @click:append="show3 = !show3"
              outlined
              label="Confirmación de contraseña"
              :rules="[rules.required, passwordConfirmationRule]"
              v-model="passwordConfirm"
            ></v-text-field>
            <p style="color: red">{{ loginError }}</p>
            <v-btn color="purple" dark style="width: 100%" @click="register()">
              Registrarme
            </v-btn>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>
<script>
import logo from "../assets/logo_zertu.png";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Requerido",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no válido.";
        },
        confirmPasswordRules: [
          (value) => !!value || "type confirm password",
          (value) =>
            value === this.passwordLogin ||
            "The password confirmation does not match.",
        ],
      },
      name: "",
      whatsapp: "",
      errorLogin: false,
      loginError: "",
      showRegister: false,
      phone: "",
      show3: false,
      passwordConfirm: "",
      recoveryError: false,
      showRecovery: false,
      recoverySent: false,
      logo: logo,
      show: false,
      username: this.$store.state.username,
      usernameLogin: "",
      passwordLogin: "",
      loginLoading: false,
      accessToken: this.$store.state.accessToken,
    };
  },
  async mounted() {
    this.$root.$on("openLogin", () => {
      this.show = true;
    });
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.passwordLogin === this.passwordConfirm ||
        "Los passwords no coinciden";
    },
  },

  methods: {
    showRegisterModal() {
      this.showRegister = true;
      this.showRecovery = false;
      this.recoveryError = false;
      this.recoverySent = false;
      this.show = false;
    },
    logout() {
      this.$store.commit("setAccessToken", null);
      this.$store.commit("emptyCart");
      this.$router.replace("/");
    },
    recovery() {
      this.showRecovery = true;
      this.recoveryError = false;
      this.recoverySent = false;
      this.show = false;
    },
    validateLogin() {
      if (this.$refs.loginForm.validate()) {
        this.login();
      }
    },
    sendRecovery() {
      this.loginLoading = true;
      var request = {
        email: this.usernameLogin,
      };
      this.axios
        .post("/user/password/recovery", request)
        .then(() => {
          this.loginLoading = false;
          this.recoverySent = true;
        })
        .catch(() => {
          this.loginLoading = false;
          this.recoveryError = true;
        });
    },
    register() {
      this.$refs.formRegister.validate();
      if (this.$refs.formRegister.validate()) {
        this.loginLoading = true;
        var request = {
          email: this.usernameLogin,
          username: this.usernameLogin,
          name: this.name,
          password: this.passwordLogin,
          whatsapp: this.whatsapp,
          address_line_1: "",
          address_line_2: "",
        };
        this.axios
          .post("/user/register", request)
          .then((response) => {
            this.loginLoading = false;
            if (response.data.code != 500) {
              this.login();
              this.showRegister = false;
              this.showRecovery = false;
              this.recoveryError = false;
              this.recoverySent = false;
              console.log(response);
            } else {
              console.log("Error");
              this.loginError = "Usuario ya registrado";
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },
    login() {
      this.loginLoading = true;
      var qs = require("qs");
      var settings = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      var data = {
        client_id: "zertu",
        client_secret: "zertu",
        grant_type: "password",
        password: this.passwordLogin,
        username: this.usernameLogin,
        scope: "read write",
      };
      this.axios
        .post("/oauth/token", qs.stringify(data), settings)
        .then((response) => {
          console.log(response.data.access_token);
          var token = response.data.access_token;
          this.$store.commit("setAccessToken", token);
          this.axios
            .get("/user/profile", {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then((response2) => {
              this.loginLoading = false;
              this.$router.go(this.$router.currentRoute)
              this.$store.commit("setUsername", response2.data.response.name);
              this.show = false;
            });
        })
        .catch((response) => {
          this.loginLoading = false;
          this.errorLogin = true;
          console.log(response);
        });
    },
  },
};
</script>
<style>
.v-btn__content {
  display: flex;
  flex-direction: column;
}
.v-btn--icon.v-size--default {
  width: auto !important;
  padding-left: 20px;
  padding-right: 20px;
}
.v-badge__wrapper {
  top: -20px;
}
</style>
