export const structOrder = orders =>{ 
    const addedOrders = []  
    return orders.map(d=> {
        if(!addedOrders.find(x=> x === d.orderId)){
            addedOrders.push(d.orderId)
            return (
            {
            orderId:d.orderId, 
            created: new Date(d.dateCreated),
            reference: d.reference,
            amount: orders.length === 1 ? d.amount : orders.filter(f=> f.orderId === d.orderId).map(m=> m.amount).reduce((acc, val) => acc + val ),
            datePaid: d.datePaid
            }
         )
        }
    })
}