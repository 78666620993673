import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Course from '../views/Course.vue'
import Carrito from '../views/Cart'
import Gracias from '../views/ThankYou.vue'
import GraciasEcommerce from '../views/ThankYouEcommerce';
import Certificacion2 from '../views/Certificacion2.vue'
import Modulo from '../views/Modulo.vue'
import Aviso from '../views/Aviso.vue'
import Terminos from '../views/TerminosYCondiciones.vue'
import Profile from '../views/Profile.vue'
import Shop from '../views/shop/List.vue'
import EcommerceCart from '../views/CartEcommerce.vue'
import CartSelector from '../views/CartSelector.vue'
import ProductDetail from '../views/shop/ItemDetail.vue'
import Book from '../views/Book.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/course/:id',
    name: 'Course',
    component: Course
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import('../views/Payments')
  },
  {
    path: '/payment-reference',
    name: 'Payment Reference',
    component: () => import('../views/PaymentReference')
  },
  {
    path: '/carrito',
    name: 'Carrito',
    component: Carrito
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CartSelector
  },
  {
    path: '/carrito-certificaciones',
    name: 'Carrito Certificaciones',
    component: () => import('../views/CartCertifications')
  },
  {
    path: '/carrito-tienda',
    name: 'CarritoTienda',
    component: EcommerceCart
  },
  {
    path: '/gracias',
    name: 'gracias',
    component: Gracias,
    props: true
  },
  {
    path: '/gracias-certificaciones',
    name: 'graciasCertificaciones',
    component: () => import('../views/ThankYouCertifications')
  },
  {
    path: '/gracias-ecommerce',
    name: 'gracias-ecommerce',
    component: GraciasEcommerce,
    props: true
  },
  {
    path: '/master-evolucion',
    name: 'master',
    component: Certificacion2,
    props: true
  },
  {
    path: '/terminos',
    name: 'terminos',
    component: Terminos,
    props: true
  },
  {
    path: '/aviso',
    name: 'aviso',
    component: Aviso,
    props: true
  },
  {
    path: '/certificacion/:slug',
    name: 'CertificacionSlug',
    component: Certificacion2
  },
  {
    path: '/book/:id',
    name: 'book',
    component: Book,
    props: true
  },
  {
    path: '/producto/:id',
    name: 'producto',
    component: ProductDetail,
    props: true
  },
  { path: '/modulo/:id', component: Modulo, name: "modulo" },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/vegan',
    redirect: () => {

      return { path: '/course/106' }
    },
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
