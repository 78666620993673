<template>
    <div class="container" style="margin-top:200px;">
        <h1>Aviso de privacidad</h1>
        <p>Con fundamento en lo dispuesto por los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, hacemos de su conocimiento que ZTU, S.C. (en adelante “<strong>ZERTÚ</strong>”), con domicilio en Bosque de Olmos No. 51, Colonia Bosques de las Lomas, Delegación Cuajimalpa de Morelos, Ciudad de México, CP 05120, es responsable de recabar, resguardar y proteger sus datos personales.</p>
<p>Este Aviso de Privacidad contempla en todo momento los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad en el tratamiento de los datos personales.</p>
<ol start="1" type="1">
  <li><strong>Uso de la información.</strong></li>
</ol>
<p>Su información personal será utilizada para las siguientes finalidades:</p>
<p><strong>1) &nbsp; &nbsp; &nbsp;</strong>Notificarle sobre nuevas actividades y contenido, así como cambios al mismo;</p>
<p><strong>2) &nbsp; &nbsp; &nbsp;</strong>Evaluar la calidad del servicio;</p>
<p><strong>3) &nbsp; &nbsp; &nbsp;</strong>Realizar evaluaciones periódicas de las actividades y contenidos que brindamos, a efecto de mejorar la calidad de los mismos;</p>
<p><strong>4) &nbsp; &nbsp; &nbsp;</strong>Dar cumplimiento a las obligaciones que hemos contraído con Usted;</p>
<p><strong>5) &nbsp; &nbsp; &nbsp;</strong>Notificarle promociones, ofertas y servicios mediante avisos y mensajes promocionales;</p>
<p><strong>6) &nbsp; &nbsp; &nbsp;</strong>Contactarlo cuando sea necesario completar procedimientos para la adquisición de contenidos y cualesquier otros productos;</p>
<p><strong>7) &nbsp; &nbsp; &nbsp;</strong>Responder dudas y comentarios; y</p>
<p><strong>8) &nbsp; &nbsp; &nbsp;</strong>Proveerle una mejor atención.</p>
<ol start="1" type="1">
  <li><strong>Datos personales.</strong></li>
</ol>
<p>Para las finalidades antes mencionadas, podríamos solicitar de Usted los siguientes datos personales:</p>
<p><strong>1) &nbsp; &nbsp; &nbsp;</strong>Nombre completo;</p>
<p><strong>2) &nbsp; &nbsp; &nbsp;</strong>Fecha de nacimiento;</p>
<p><strong>3) &nbsp; &nbsp; &nbsp;</strong>Edad;</p>
<p><strong>4) &nbsp; &nbsp; &nbsp;</strong>Estado civil;</p>
<p><strong>5) &nbsp; &nbsp; &nbsp;</strong>Sexo;</p>
<p><strong>6) &nbsp; &nbsp; &nbsp;</strong>Teléfono fijo y/o celular;</p>
<p><strong>7) &nbsp; &nbsp; &nbsp;</strong>Correo electrónico;</p>
<p><strong>8) &nbsp; &nbsp; &nbsp;</strong>Domicilio; y</p>
<p><strong>9) &nbsp; &nbsp; &nbsp;</strong>Datos de tarjetas bancarias.</p>
<p>La información que nos proporcione deberá ser veraz y completa, en el entendido que Usted será responsable en todo momento por los datos proporcionados y en ningún caso <strong>ZERTÚ</strong> será responsable de la veracidad de los mismos.</p>
<ol start="1" type="1">
  <li><strong>Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición).</strong></li>
</ol>
<p>Es importante hacer de su conocimiento que Usted tiene derecho al acceso, rectificación y cancelación de sus datos personales, así como a oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin Usted nos haya otorgado. Para ello, es necesario que envíe la solicitud en términos de lo dispuesto por el artículo 29 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, a la dirección ubicada en Bosque de Olmos No. 51, Colonia Bosques de las Lomas, Delegación Cuajimalpa de Morelos, Ciudad de México, CP 05120, o bien, vía correo electrónico a contact@zertu.mx, a fin de garantizar su correcta recepción. Su solicitud deberá ir acompañada de los argumentos por los que desea ejercer cualquiera de sus derechos, junto con una identificación oficial del titular de los datos o su apoderado. La petición que al efecto reciba <strong>ZERTÚ</strong>, será atendida en un plazo no mayor a 30 (treinta) días hábiles.</p>
<ol start="1" type="1">
  <li><strong>Transferencia de información con terceros.</strong></li>
</ol>
<p><strong>&nbsp;</strong></p>
<p><strong>ZERTÚ</strong> no realiza transferencias de información con terceros. Sólo se podrá difundir la información en casos especiales, cuando pueda servir para identificar, localizar o ejercitar acciones legales contra personas que pudiesen infringir las condiciones del servicio de nuestro sitio web o causar daños o interferencia sobre los derechos de <strong>ZERTÚ</strong>, o sus propiedades, de otros usuarios del portal o de cualquier otra persona que pudiese resultar perjudicada por dichas actividades.</p>
<ol start="1" type="1">
  <li><strong>Uso de “cookies” y “web beacons”.</strong></li>
</ol>
<p>Las “cookies” son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de internet específica, que permiten recordar al servidor de internet algunos datos sobre este usuario, entre ellos, sus preferencias para la visualización de las páginas en ese servidor, nombre y contraseña, así como medir la frecuencia de accesos al sitio web y de la navegación en general.</p>
<p>Por su parte, las “web beacons” son imágenes insertadas en una página de internet o correo electrónico, que pueden ser utilizadas para monitorear el comportamiento de un visitante, como almacenar información sobre la dirección IP del usuario, duración del tiempo de interacción en dicha página y el tipo de navegador utilizado, entre otros.</p>
<p>Le informamos que utilizamos “cookies” y “web beacons” para obtener y conocer información como la siguiente:</p>
<p><strong>1) &nbsp; &nbsp; &nbsp;</strong>Tipo de navegador y sistema operativo;</p>
<p><strong>2) &nbsp; &nbsp; &nbsp;</strong>Recibir información sobre nuestros servicios de red, para fines estadísticos;</p>
<p><strong>3) &nbsp; &nbsp; &nbsp;</strong>Realizar evaluaciones periódicas de las actividades y contenidos que brindamos, a efecto de mejorar la calidad de los mismos;</p>
<p><strong>4) &nbsp; &nbsp; &nbsp;</strong>Dar cumplimiento a las obligaciones que hemos contraído con Usted; y</p>
<p><strong>5) &nbsp; &nbsp; &nbsp;</strong>Notificarle promociones, ofertas y servicios mediante avisos y mensajes promocionales.</p>
<p>Asimismo hacemos de su conocimiento que algunas de las “cookies” serán transferidas de nuestro servidor al sistema de su computadora, siendo que la mayoría de las veces se tratan de “Session-Cookies”. Las “Session-Cookies” se borran automáticamente del disco duro de su computadora después de terminar la sesión del navegador. Otras “cookies” permanecen en el sistema de su computadora y nos permiten reconocer su sistema la próxima vez que inicie sesión (“cookies” permanentes). Usted decide si los “cookies” pueden ser almacenados o no al programar su navegador de una forma que le informe antes de archivar las “cookies”. De esta forma, el almacenamiento de las “cookies” será permitido únicamente después de recibir su consentimiento explícito.</p>
<p>En el caso de empleo de “cookies”, el botón de “ayuda” que se encuentra en la barra de herramientas de la mayoría de los navegadores le dirá cómo evitar aceptar nuevas “cookies”, cómo hacer que el navegador le notifique cuando recibe un nuevo “cookie” o cómo deshabilitar todos los “cookies”.</p>
<ol start="1" type="1">
  <li><strong>Aceptación de los términos.</strong></li>
</ol>
<p><strong>&nbsp;</strong></p>
<p>Al utilizar los servicios del sitio web de <strong>ZERTÚ</strong>, Usted acepta haber leído y aceptado los términos de este Aviso de Privacidad.</p>
<p><strong>&nbsp;</strong></p>
<ol start="1" type="1">
  <li><strong>Modificaciones al Aviso de Privacidad.</strong></li>
</ol>
<p><strong>ZERTÚ</strong> se reserva el derecho de efectuar, en cualquier momento, modificaciones o actualizaciones al presente Aviso de Privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para el ofrecimiento de nuestras actividades, contenidos y productos.</p>
<p>Cualquier modificación a este Aviso de Privacidad podrá consultarlo en&nbsp;
  <a href="http://www.zertu.mx/">http://www.zertu.mx/</a>
</p>
<ol start="1" type="1">
  <li><strong>Autoridad.</strong></li>
</ol>
<p><strong>&nbsp;</strong></p>
<p>Si Usted considera que han sido vulnerados sus derechos respecto de la protección de datos personales, tiene derecho a presenta ante el Instituto Federal de Acceso a la Información, la queja o denuncia que en su caso corresponda. Para mayor información visite&nbsp;
  <a href="http://www.ifai.org.com/">www.ifai.org.com</a>.
</p>
    </div>
</template>

<script>
export default {

}
</script>
