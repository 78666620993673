<template>
  <v-app>
    <Navbar></Navbar>
    
    <v-main style="padding:30px;">
      
      <router-view/>
    </v-main>
      <v-footer
    dark
    padless
  >
    <v-card
      flat
      tile
      class="turquoise white--text text-center"
      style="width: 100%;"
    >


      <v-card-text class="white--text pt-0" style="color:white!important;">
        <p style="padding-top:15px;font-size:20px;">Consulta nuestros <a href="/terminos" style="color:white;">Términos y condiciones</a> y <a href="/aviso" style="color:white;">Aviso de privacidad</a></p>
      </v-card-text>

      <v-divider></v-divider>
      <div id="payment-element">
            <!-- Elements will create form elements here -->
        </div>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Zertu</strong>
      </v-card-text>
      
    </v-card>
  </v-footer>
  </v-app>
</template>
<style>
input {
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}
</style>
<script>
import Navbar from './components/Navbar.vue'
export default {
  name: 'App',
  components:{
    Navbar
  },
    metaInfo: {
    title: 'Zertú',
  }
};
</script>
