import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import router from '../router'
import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    accessToken:null,
    username:null,
    cart:[],
    cartEcommerce:[],
    cartCertifications:[],
    paymentReference:null,
    productsOrder:null,
    storeCertifications: {},
    paypal:{
      sandbox: 'AR60eIVybDRMrf-AoIJn-nRKxoPTGa5LjvybJvnRTJH9OQKHTkdxGSo46gRshKtFAmWUm2rdkDQ5Tlp0',
      production: 'AZsdBFpWku8AXIHlXvYndAZ_yr9dh89e6-3j1Ybu7bEBXr0cZigM1OOUoxfY0XUDQXV7GGTTygWqxf1v'
    },
    client_secret: 'zertu',
    stripeKey: {
     // key:'pk_test_51NIowKAenCgsJE8c4G8Lip6jvPI6CNB268CyolzfO9grgPff5k1hUpBl6wqnIslLw9dvySjY8Ugg01fu31rOZExV00LT7njH0Y' //local
      key:'pk_live_51NDWEFGk2JcBer3fjypYADcBqyqW3eHdD2bWaROht1ONkxdttmL5yv7RjXjaOQhEowEsKbclSaJ8z3TzKqMK7W8h00lRjdHaxJ'
    }
  },
  mutations: {
    setCertifications (state, data){
      state.storeCertifications = data
    },
    setAccessToken (state,token) {
      state.accessToken=token;
    },
    setUsername (state,name) {
      state.username=name;
    },
    addCourseToCart(state,course){
      state.cart.push(course);
    },
    addCertificationToCart(state,certification){
      state.cartCertifications.push(certification)
    },
    addItemToEcommerceCart(state,item){
      state.cartEcommerce.push(item);
    },
    emptyEcommerceCart(state){
      state.cartEcommerce=[];
    },
    emptyCart(state){
      state.cart=[];
    },
    emptyCertCart(state){
      state.cartCertifications=[]
    },
    removeCourseFromCart(state,course){
      const index = state.cart.indexOf(course);
      if (index > -1) {
        state.cart.splice(index, 1);
      }
    },
    removeCourseFromEcommerceCart(state,item){
      const index = state.cartEcommerce.indexOf(item);
      if (index > -1) {
        state.cartEcommerce.splice(index, 1);
      }
    },
    removeCertificationFromCart(state,item){
      const index = state.cartCertifications.indexOf(item);
      if (index > -1) {
        state.cartCertifications.splice(index, 1);
      }
    },
    setPaymentReference(state,data){
      state.paymentReference = data
    },
    setProductsOrder(state,data){
      console.log(data)
      state.productsOrder = data
    }
  },
  actions: {
    OpenOxxoReference({commit},data){
      commit('setPaymentReference', data.order)
      data.router.push('/payment-reference')
    },
    LogoutException({commit}){
      commit("setAccessToken", null);
      commit("emptyCart");    
      router.replace("/");
    },
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]

})
