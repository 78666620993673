<template>
    
  <div class="card text-left" style="padding:30px;border:1px solid #ccc;">
    <div
      class="card-img-top"
      v-bind:style="{
        backgroundImage: 'url(' + product.images[0].src + ')',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        width: '100%',
        paddingTop: '100%',
        position: 'relative',
        backgroundPosition: 'center center',
      }"
    ></div>
    <div class="card-body">
      <h4 class="card-title">{{ product.name }}</h4>
      <p
        class="card-text"
        v-if="
          product.regular_price == product.sale_price ||
          product.sale_price == ''
        "
      >
        $ {{ product.regular_price }}
      </p>
      <p
        class="card-text"
        style="text-decoration: line-through"
        v-if="
          product.regular_price != product.sale_price &&
          product.sale_price != ''
        "
      >
        Precio regular: ${{ product.regular_price }}
      </p>
      <p
        class="card-text"
        v-if="
          product.regular_price != product.sale_price &&
          product.sale_price != ''
        "
      >
        Promoción: ${{ product.sale_price }}
      </p>
      <v-btn
        color="primary"
        v-on:click="goToProduct(product)"
        class="btn btn-primary"
        style="width: 100%"
        >Ver Producto</v-btn
      >
      <div class="row" style="margin-top: 40px">
        <div class="col-12">
          <v-text-field
            label="Cantidad"
            type="number"
            min="1"
            v-model="product.quantity"
            inline
            center
            controls
          ></v-text-field>
        </div>
        <div class="col-12">
          <v-btn
            color="primary"
            v-on:click="addToCart"
            class="btn btn-primary"
            style="width: 100%"
          >
          Agregar al carrito
          </v-btn>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar" absolute bottom color="success" outlined>
      Producto agregado

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props: ["product"],
  mounted() {
    console.log(this.product.quantity);
    
  },
  data(){
    return({
        snackbar:false
    })
  },
  methods: {
    goToProduct(product) {
      this.$router.push(`producto/${product.id}`)
    },
    addToCart() {
        this.$store.commit("addItemToEcommerceCart", this.product);
        this.snackbar = true;
    },
  },
};
</script>
