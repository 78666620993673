import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/main.css';
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import vuetify from './plugins/vuetify'
import vueVimeoPlayer from 'vue-vimeo-player'
import { VueMaskDirective } from 'v-mask'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.directive('mask', VueMaskDirective);
Vue.use(vueVimeoPlayer)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.config.productionTip = false
axios.defaults.baseURL=process.env.VUE_APP_API_URL
//axios.defaults.baseURL='http://localhost:8090'

Vue.filter('toCurrency', function(n) {
  if(!n || typeof n !== 'number') n = 0
  return n.toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})
})

Vue.use(VueAxios, axios)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
