<template>

<div style="margin-top:120px;">
    <div
      v-if="loading"
      style="
        margin-top: 100px;
        justify-content: center;
        align-items: center;
        display: flex;
      "
    >
      <v-progress-circular
        :size="50"
        color="turquoise"
        indeterminate
      ></v-progress-circular>
    </div>
    <div class="row" style="margin-top:40px;">
        <div class="col-md-12">
        <div class="row"  v-if="!loading">

            <div class="col-md-3">
                <h4>Categorías</h4>
                <ul style="list-style-type:none;">

                    <li v-for="category in categories" v-bind:key="category.id">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input class="form-check-input" v-on:change="filter" v-model="categories_selected" :value="category.id" type="checkbox" name="" id=""> {{category.name}}
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-6" v-bind:key="product.id" style="margin-bottom:20px;" v-for="product in products">
                        <ProductComponent :product="product"></ProductComponent>
                    </div>
                </div>
            </div>

        </div>
        </div>
    </div>

</div>

</template>
<script>
import ProductComponent from './components/ProductComponent.vue'
export default {
    components:{
        ProductComponent
    },
    mounted() {
        var vm=this;
        this.axios.get('/store/products', {
        }).then((value) => {
            var productsNotFiltered=value.data.response;
            productsNotFiltered.forEach(element=>{
                if(element.name!='CURSO LA SANADORA DE ALMAS'){
                    vm.productsAll.push(element); 
                    vm.products.push(element); 
                }
            });
            vm.loading=false;
            vm.products.forEach(element => {
                element.quantity=1;
                element.categories.forEach(element2 => {
                    var found=false;
                    vm.categories.forEach(element3 => {
                        if(element3.id==element2.id){
                            found=true;
                        }
                    });
                    if(!found){
                        vm.categories.push(element2);
                    }


                });
            });
        });

    },
    data() {
        return {
            loading:true,
            products:[],
            categories:[],
            productsAll:[],
            categories_selected:[]
        }
    },
    methods: {
        goToProduct(product){
            this.$router.push({ name: 'producto', params: {product: product }})
        },
        filter(){
            var vm=this;
            vm.products=[];
            vm.productsAll.forEach(element => {
                element.categories.forEach(element2 => {
                    vm.categories_selected.forEach(element3=>{
                        if(element2.id==element3){
                            vm.products.push(element);
                        }
                    })

                });
            });
            if(vm.categories_selected.length==0){
                vm.products=vm.productsAll;
            }
        }
    },

}
</script>
