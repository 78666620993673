<template>
  <div class="container" style="margin-top: 100px">
    <v-row justify="center" align="center" v-if="modulo && modulo.banner">
      <v-col cols="12" md="12" align="center">
        <v-img :lazy-src="modulo.banner" :src="modulo.banner" max-width="1000" max-height="700"></v-img>
      </v-col>
    </v-row>
    <div class="row" v-if="loading"></div>
    <div class="row" v-if="!loading">
      
      <div class="col-md-12">
        <h1 class="f-24" style="text-align: center;" v-if="modulo.titulo">
         {{ modulo.titulo }}
        </h1>
      </div>
    </div>
    <v-row justify="center">
      <v-col cols="12" md="6" lg="6" xl="5" align="center" class="f-24" v-if="modulo && modulo.subtitle">
        <b v-html="modulo.subtitle"></b>
      </v-col>
    </v-row>
    <v-row v-if="modulo && modulo.videoUrl" justify="center">
      <v-col cols="12" align="center">
        <vimeo-player  style="border-radius: 30px; width: 100%" ref="player" :video-url="modulo.videoUrl"/>
      </v-col>
    </v-row>
    <v-row v-if="modulo && modulo.closingVideoUrl" justify="center">
      <v-col cols="12" align="center">
        <p>Cierre de modulo</p>
      </v-col>
      <v-col cols="12" align="center">
        <vimeo-player style="border-radius: 30px; width: 100%" ref="player2" :video-url="modulo.closingVideoUrl"></vimeo-player>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="modulo && modulo.instrucciones && modulo.completed === false">
      <v-col cols="12" md="6" align="start"><b class="f-15">Instrucciones:</b></v-col>
      <v-col cols="12">
        <v-row  justify="center">
          <v-col cols="12" md="6" align="start">
            <div class="f-15" v-html="modulo.instrucciones"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="buttonLinks.length>0">
      <v-col cols="12" md="12"  lg="6" align="start" class="f-20"><b>Descargas:</b></v-col>
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="12" md="12" lg="3" align="start" v-for="(button,i) in buttonLinks" :key="i">
            <div class="primary text-center pa-4 white--text f-15 pointer" @click="OpenExternal(button.content)" style="border-radius:10px;"><b>{{button.text}}</b></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <v-row justify="center" class="mt-6" v-if="modulo && modulo.contenido && modulo.completed === false">
      <v-col cols="12" md="6" align="start">
        <div class="f-15" v-html="modulo.contenido"></div>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="modulo && modulo.programa">
      <v-col cols="12" md="6" align="start" class="f-24"><b>Programa: </b></v-col>
      <v-col cols="12">
        <v-row justify="center" >
          <v-col cols="12" md="6" align="start">
            <div class="f-15" v-html="modulo.programa"></div>
          </v-col>
         </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="videoLinks">
      <v-col cols="12" v-for="video, i in videoLinks" :key="i">
        <v-row justify="center" >
          <v-col cols="12" class="pt-10"><v-divider/></v-col>
          <v-col cols="12" class="f-15 " v-html="video.text" justify="center" align="center"></v-col>
          <v-col cols="12" md="6" v-if="isYoutubeVideo(video.content)">
            <iframe  width="560" height="315" :src="video.content" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </v-col>
          <v-col v-else>
            <vimeo-player  v-bind:key="i" style="border-radiux: 30px; width: 100%" ref="player" :video-url="video.content" />
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>
    <v-row justify="center" v-if="nextProgram">
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="12" md="6" align="center">    
            <div class="primary text-center pa-4 white--text f-24 pointer" @click="OpenCourse(nextProgram.id)" style="border-radius:10px;"><b>Ir al curso</b></div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" align="center">
        <v-img :lazy-src="nextProgram.newImageUrl" :src="nextProgram.newImageUrl" width="500" height="300"></v-img>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" v-if="modulo && modulo.zoomText && modulo.zoomDate && modulo.zoom_url && modulo.completed === false">
      <v-col cols="6"><v-divider></v-divider></v-col>
      <v-col cols="12" align="center" class="f-24">Zoom</v-col>
      <v-col cols="12" align="center" class="f-24">{{modulo.zoomDate}}</v-col>
      <v-col cols="12" md="4" lg="4" align="center"> 
        <div class="primary text-center pa-4 white--text f-24 pointer" @click="OpenExternal(modulo.zoom_url)" style="border-radius:10px;"><b>Enlace a zoom</b></div>
      </v-col>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" class="text-center">
            <div v-html=" modulo.zoomText "></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="row" v-if="!loading">
      <div class="col-md-6" v-if="modulo.videos.length > 0">
        <vimeo-player
          v-for="video in modulo.videos"
          v-bind:key="video.id"
          style="border-radiux: 30px; width: 100%"
          ref="player"
          :video-url="video.url"
        />
      </div>
      <!--<div class="col-md-6">
        <p v-html="modulo.descripcion"></p>
      </div>-->
      <div
        class="col-md-12"
        style="margin-top: 30px"
        v-if="modulo.aprobado == true"
      >
        <h2 style="text-align: center; font-size: 30px">Evaluación</h2>
        <p style="text-align: center; font-size: 30px">
          ¡Felicidades ya has aprobado este módulo!
        </p>
        <p style="text-align: center; font-size: 30px">
          Las respuestas de tu evaluación se muestran a continuación
        </p>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(pregunta, index) in modulo.respuestasDelUsuario"
            v-bind:key="pregunta.id"
          >
            <v-expansion-panel-header>
              Pregunta {{ index + 1 }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span v-html="pregunta.pregunta.pregunta"></span>
              <h3>Tu respuesta fue:</h3>
              <p style="color: green" v-if="pregunta.correcta">
                {{ pregunta.respuesta }}
              </p>
              <p style="color: red" v-if="!pregunta.correcta">
                {{ pregunta.respuesta }}
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn
          v-if="modulo.siguienteModulo != null"
          dark
          class="purple"
          style="width: 100%; margin-top: 20px"
          v-on:click="goToModule(modulo.siguienteModulo)"
          >Siguiente Módulo</v-btn
        >
        
      </div>
      <v-row  class="col-md-12" v-if="storeCertifications.pagada && terminada && modulo.siguienteModulo === null">
       <v-col cols="12">
        <div v-if="certificateSent">
          <h2>Tu certificado ha sido enviado a tu correo</h2>
        </div>
        <div v-if="sendingCertificate&&!certificateSent">
          <h2>Enviando Certificado</h2>
        </div>
        <div v-if="!sendingCertificate&&!certificateSent">
          <h2>Has terminado esta certificación</h2>
          <p style="text-align:center;">Puedes solicitar tu certificado, solo llena tu nombre completo y da click en el botón</p>
          <v-text-field
              type="text"
              name="input-10-2"
              label="Nombre completo"
              outlined
              
              style="width:70%;margin-left:auto;margin-right:auto;"
              v-model="fullname"
            ></v-text-field>
            <v-row justify="center" align="center">
              <v-col justify="center" align="center">
                <v-btn class="btn btn-primary purple" dark  v-on:click="reenviarCertificado()" style="text-align:center;">Enviar a mi correo</v-btn>
              </v-col>
            </v-row>
        </div>
       </v-col>
      </v-row>
      <div
        class="col-md-12"
        style="margin-top: 30px"
        v-if="modulo.aprobado == false && modulo.preguntas.length > 0"
      >
        <v-stepper v-model="e6" vertical>
          <v-stepper-step
            v-for="(pregunta, index) in modulo.preguntas"
            v-bind:key="index"
            :complete="e6 > index + 1"
            :step="index + 1"
            >Pregunta {{ index + 1 }}
            <v-stepper-content :step="index + 1">
              <span v-html="pregunta.pregunta"></span>
              <div
                class="form-check"
                v-for="respuesta in pregunta.respuestas"
                v-bind:key="respuesta.id"
              >
                <label class="form-check-label">
                  <input
                    v-model="pregunta.respuestaSeleccionada"
                    type="radio"
                    :id="respuesta.id"
                    class="form-check-input"
                    :value="respuesta.id"
                    :name="pregunta.id"
                    style="margin-top: 10px"
                  />
                  {{ respuesta.respuesta }}
                </label>
              </div>
              <div style="margin-top: 30px">
                <v-btn
                  color="primary"
                  style="margin-right: 20px"
                  @click="e6 = index + 2"
                >
                  Siguiente
                </v-btn>
                <v-btn @click="e6 = index"> Regresar </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-step>
        </v-stepper>
        <v-btn
          v-on:click="evaluar"
          class="purple"
          dark
          style="margin-top: 40px; width: 100%"
          >Evaluar</v-btn
        >
      </div>
    </div>
    <v-dialog v-model="evaluando" width="500">
      <v-card :loading="mensajeEvaluando=='Evaluando'">
        <v-card-title class="text-h5 grey lighten-2">
          Evaluación
        </v-card-title>

        <v-card-text style="text-align:center;font-size:20px;margin-top:20px;">
            {{ mensajeEvaluando }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="evaluando = false"
            v-if="mensajeEvaluando=='No has aprobado el módulo, por favor intenta otra vez'"
          >
            Reintentar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="goToModule(modulo.siguienteModulo)"
            v-if="mensajeEvaluando=='¡Felicidades, has aprobado el módulo!'"
          >
            Siguiente módulo
          </v-btn>          
        </v-card-actions>
      </v-card>        
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      e6: 1,
      certificateSent:false,
      sendingCertificate:false,
      mensajeEvaluando: "Evaluando",
      respuestas: [],
      loading: true,
      modulo: null,
      evaluando:false,
      nextProgram:null,
      buttonLinks: [],
      videoLinks: [],
      fullname: null,
    };
  },

  mounted() {
    //this.obtenerCertificaciones();
    this.obtenerModulo();
  },
  computed:{
    terminada(){
      /**
       *Maybe should replace this code! 
      */
       // return !this.certificaciones.modulos.find(x=> !x.aprobado)
      var aprobadas=0;
      if(this.storeCertifications){
        this.storeCertifications.modulos.forEach(element => {
          if(element.aprobado){
            aprobadas++;
          }
        });
      }
      if(aprobadas+1>=this.storeCertifications.modulos.length){
        return true;
      }else{
        return false;
      }
    },
    ...mapState(['storeCertifications'])
  },
  methods: {
    isYoutubeVideo(url){
      return url.includes('youtube.com/') || url.includes('youtu.be/') || url.includes('m.youtube.com/')
    },
    obtenerCertificaciones() {
      if (this.$store.state.accessToken != null) {
        var headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.accessToken,
        };
        this.axios
          .get("/certificacion/slug/" + this.$route.params.slug, {
            headers: headers,
          })
          .then((response) => {
            this.loading = false;
           
            this.$store.commit('setCertifications',  response.data.response )
          });
      } else {
        this.axios
          .get("/certificacion/slug/" + this.$route.params.slug)
          .then((response) => {
            this.loading = false;
            this.$store.commit('setCertifications',  response.data.response )
          });
      }
    },
    
    reenviarCertificado(){
      this.sendingCertificate=true;
      var body={
        nombre:this.fullname,
        correo:"atorres@applab.mx",
        fontSize:20,
      }
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/certificacion/prueba", body, {
          headers: headers,
        })
        .then((response) => {

          let binaryString = window.atob(response);
          let binaryLen = binaryString.length;
          let bytes = new Uint8Array(binaryLen);

          for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }

          let blob = new Blob([bytes], { type: "application/pdf" });
          let link = URL.createObjectURL(blob);
          window.open(link, "_blank");
          this.sendingCertificate=false;
          this.certificateSent=true;
        }).catch(()=>{
          this.sendingCertificate=false;
          this.certificateSent=true;
        });
       
    },
    OpenExternal(url){
      window.open(url)
    },
    OpenCourse(id){
      this.$router.push("/course/" + id);
    },
    evaluar() {
        this.evaluando=true;
      var vm = this;
      var request = [];
      this.modulo.preguntas.forEach((element) => {
        if (element.respuestaSeleccionada != null) {
          request.push({
            preguntaId: element.id,
            respuestaId: element.respuestaSeleccionada,
          });
        }
      });
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/certificacion/evaular/" + this.$route.params.id, request, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.response.promedio >= 80) {
            vm.mensajeEvaluando = "¡Felicidades, has aprobado el módulo!";
            vm.modulo.aprobado = true;
            vm.obtenerModulo();
          } else {
            vm.mensajeEvaluando =
              "No has aprobado el módulo, por favor intenta otra vez";
          }
        }).catch(e => {
          if(e.response.status === 401)
            this.$store.dispatch('LogoutException')
        })
    },
    goToModule(id) {
      this.$router.push("/modulo/" + id);
      this.$router.go();
    },
    generarCertificado() {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
        responseType: "blob",
      };
      this.axios
        .get(
          "/certificacion/certificado/generar/" + this.modulo.certificacionId,
          { headers: headers }
        )
        .then(function (response) {
          let binaryString = window.atob(response);
          let binaryLen = binaryString.length;
          let bytes = new Uint8Array(binaryLen);

          for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }

          let blob = new Blob([bytes], { type: "application/pdf" });
          let link = URL.createObjectURL(blob);
          window.open(link, "_blank");
        }).catch(e => {
          if(e.response.status === 401)
            this.$store.dispatch('LogoutException')
        })
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    saveByteArray(byte) {
      const pdfBlob = new Blob([byte], { type: "application/pdf" });

      const blobUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "CertificadoZertu.pdf");
      link.click();
      link.remove();
      URL.revokeObjectURL(blobUrl);
    },
    obtenerModulo() {
      if (this.$store.state.accessToken != null) {
        var headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.accessToken,
        };
        this.axios
          .get("/certificacion/modulo/" + this.$route.params.id, {
            headers: headers,
          })
          .then((response) =>{
            this.loading = false;
            this.modulo = response.data.response.response;
            this.nextProgram = response.data.nextProgram
            this.buttonLinks = response.data.links.filter(x=> x.button)
            this.videoLinks = response.data.links.filter(x=> !x.button)

            if(this.buttonLinks.length > 0){
              this.buttonLinks = this.buttonLinks.sort((a,b) => a.order - b.order)
            }
            if(this.videoLinks.length > 0){
              this.videoLinks = this.videoLinks.sort((a,b) => a.order - b.order)
            }
            
            this.modulo.preguntas.forEach((element) => {
              this.respuestas.push({
                preguntaId: element.id,
                respuestaId: null,
              });
            });
          }).catch(e => {
          if(e.response.status === 401)
            this.$store.dispatch('LogoutException')
        })
      }
    },
  },
};
</script>


<style scoped>
  .pointer{
    cursor: pointer;
  }
  .f-50{
    font-size:50px;
  }
  .f-36{
    font-size: 36px;
  }
  .f-30{
    font-size:30px;
  }
  .f-24{
    font-size: 24px
  }
  .f-20{
    font-size: 20px;
  }
   .f-20{
    font-size: 20px;
  }
  .f-15{
    font-size: 16px;
  }
  .f-25{
    font-size:25px;
  }
  @media(max-width:600px){
    .f-50{
      font-size: 35px;
    }
    .f-36{
      font-size: 20px;
    }
    .f-30{
      font-size:16px;
    }
    .f-25{
      font-size: 16px;
    }
  }
</style>


