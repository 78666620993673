<template>
  <div style="margin-top: 100px; text-align: center">
    <h2>Orden #{{ receipt.response.id }}</h2>
    <p style="font-size: 20px">
      Gracias por tu compra {{ $store.state.username }}
    </p>
    <h2>Confirmación de pedido</h2>
    <p>
      Numero de Orden: {{ receipt.response.id }} <br />
      Metodo de pago: Stripe Pago Con tarjeta
    </p>
    <br />
    <h2>Detalles de la orden</h2>
    <div
      v-for="item in receipt.response.line_items"
      v-bind:key="item.id"
      style="margin-top: 30px"
    >
      <v-row>
        <v-col cols="4" offset-md="4">
          <v-row>
            <v-col cols="4">
              <img
                :src="item.image.src"
                style="width: 100%; border-radius: 10px"
              />
            </v-col>
            <v-col cols="8">
              <p>{{ item.name }}</p>
              <p>Cantidad: {{item.quantity}}</p>
              <p>Total: $ {{ item.total }}</p>
            </v-col>
          </v-row>
        </v-col>
        <hr />
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  props: ["receipt"],
  data() {
      return{

      }
  },
};
</script>
