<template>
  <div style="margin-top: 100px; text-align: center">
    <h2>Orden #{{ receipt.ticket.receipt }}</h2>
    <p style="font-size: 20px">
      Gracias por tu compra {{ $store.state.username }}
    </p>
    <h2>Confirmación de pedido</h2>
    <p>
      Numero de Orden: {{ receipt.ticket.receipt }} <br />
      Fecha: {{ new Date().toLocaleDateString('es-ES') }}<br />
      Total: {{ receipt.ticket.amountPaid }}<br />
      Metodo de pago: Stripe Pago Con tarjeta
    </p>
    <br />
    <h2>Detalles de la orden</h2>
    <div
      v-for="item in receipt.courses"
      v-bind:key="item.id"
      style="margin-top: 30px"
    >
      <v-row>
        <v-col cols="4" offset-md="4">
          <v-row>
            <v-col cols="4">
              <img
                :src="item.newImageUrl"
                style="width: 100%; border-radius: 10px"
              />
            </v-col>
            <v-col cols="8">
              <p>{{ item.name }}</p>
              <p>Precio: $ {{ item.price }}</p>
              <v-btn dark class="purple" @click="$router.replace('/course/'+item.id)">Ver Curso</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <hr />
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  props: ["receipt"],
  data() {
      return{

      }
  },
};
</script>
