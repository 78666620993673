<template>
  <div class="card-form" style="margin-top: 20px">
    <div class="card-list">
      <div class="card-item" v-bind:class="{ '-active': isCardFlipped }">
        <div class="card-item__side -front">
          <div
            class="card-item__focus"
            v-bind:class="{ '-active': focusElementStyle }"
            v-bind:style="focusElementStyle"
            ref="focusElement"
          ></div>
          <div class="card-item__cover">
            <img
              v-bind:src="
                'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                currentCardBackground +
                '.jpeg'
              "
              class="card-item__bg"
            />
          </div>

          <div class="card-item__wrapper">
            <div class="card-item__top">
              <img
                src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
                class="card-item__chip"
              />
              <div class="card-item__type">
                <transition name="slide-fade-up">
                  <img
                    v-bind:src="
                      'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                      getCardType +
                      '.png'
                    "
                    v-if="getCardType"
                    v-bind:key="getCardType"
                    alt=""
                    class="card-item__typeImg"
                  />
                </transition>
              </div>
            </div>
            <label for="cardNumber" class="card-item__number" ref="cardNumber">
              <template v-if="getCardType === 'amex'">
                <span v-for="(n, $index) in amexCardMask" :key="$index">
                  <transition name="slide-fade-up">
                    <div
                      class="card-item__numberItem"
                      v-if="
                        $index > 4 &&
                        $index < 14 &&
                        cardNumber.length > $index &&
                        n.trim() !== ''
                      "
                    >
                      *
                    </div>
                    <div
                      class="card-item__numberItem"
                      :class="{ '-active': n.trim() === '' }"
                      :key="$index"
                      v-else-if="cardNumber.length > $index"
                    >
                      {{ cardNumber[$index] }}
                    </div>
                    <div
                      class="card-item__numberItem"
                      :class="{ '-active': n.trim() === '' }"
                      v-else
                      :key="$index + 1"
                    >
                      {{ n }}
                    </div>
                  </transition>
                </span>
              </template>

              <template v-else>
                <span v-for="(n, $index) in otherCardMask" :key="$index">
                  <transition name="slide-fade-up">
                    <div
                      class="card-item__numberItem"
                      v-if="
                        $index > 4 &&
                        $index < 15 &&
                        cardNumber.length > $index &&
                        n.trim() !== ''
                      "
                    >
                      *
                    </div>
                    <div
                      class="card-item__numberItem"
                      :class="{ '-active': n.trim() === '' }"
                      :key="$index"
                      v-else-if="cardNumber.length > $index"
                    >
                      {{ cardNumber[$index] }}
                    </div>
                    <div
                      class="card-item__numberItem"
                      :class="{ '-active': n.trim() === '' }"
                      v-else
                      :key="$index + 1"
                    >
                      {{ n }}
                    </div>
                  </transition>
                </span>
              </template>
            </label>
            <div class="card-item__content">
              <label for="cardName" class="card-item__info" ref="cardName">
                <div class="card-item__holder">Card Holder</div>
                <transition name="slide-fade-up">
                  <div class="card-item__name" v-if="cardName.length" key="1">
                    <transition-group name="slide-fade-right">
                      <span
                        class="card-item__nameItem"
                        v-for="(n, $index) in cardName.replace(/\s\s+/g, ' ')"
                        v-bind:key="$index + 1"
                        
                        >
                        <span v-if="$index === $index">{{ n }}</span>
                        
                        </span
                      >
                    </transition-group>
                  </div>
                  <div class="card-item__name" v-else key="2">Full Name</div>
                </transition>
              </label>
              <div class="card-item__date" ref="cardDate">
                <label for="cardMonth" class="card-item__dateTitle"
                  >Expires</label
                >
                <label for="cardMonth" class="card-item__dateItem">
                  <transition name="slide-fade-up">
                    <span v-if="cardMonth" v-bind:key="cardMonth">{{
                      cardMonth
                    }}</span>
                    <span v-else key="2">MM</span>
                  </transition>
                </label>
                /
                <label for="cardYear" class="card-item__dateItem">
                  <transition name="slide-fade-up">
                    <span v-if="cardYear" v-bind:key="cardYear">{{
                      String(cardYear).slice(2, 4)
                    }}</span>
                    <span v-else key="2">YY</span>
                  </transition>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-item__side -back">
          <div class="card-item__cover">
            <img
              v-bind:src="
                'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                currentCardBackground +
                '.jpeg'
              "
              class="card-item__bg"
            />
          </div>
          <div class="card-item__band"></div>
          <div class="card-item__cvv">
            <div class="card-item__cvvTitle">CVV</div>
            <div class="card-item__cvvBand">
              <span v-for="(n, $index) in cardCvv" :key="$index"> * </span>
            </div>
            <div class="card-item__type">
              <img
                v-bind:src="
                  'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                  getCardType +
                  '.png'
                "
                v-if="getCardType"
                class="card-item__typeImg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-form__inner">
     
      <div id="card-element" class="my-16"></div>
      <v-btn
        depressed
        dark
        color="turquoise"
        style="width: 100%; margin-bottom: 20px"
        @click="StripeAddPaymentMethod()"
      >
        Agregar
      </v-btn>
      <v-btn depressed dark color="red" style="width: 100%" @click="cerrarFormaPago"> Cancelar </v-btn>
    </div>
  </div>
</template>
<script>
import "../assets/creditcard.scss";

export default {
  props: {
    cerrarFormaPago: { type: Function },
  },
  data() {
    return {
      currentCardBackground: Math.floor(Math.random() * 25 + 1), // just for fun :D
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      //stripeKey: 'pk_test_51NIowKAenCgsJE8c4G8Lip6jvPI6CNB268CyolzfO9grgPff5k1hUpBl6wqnIslLw9dvySjY8Ugg01fu31rOZExV00LT7njH0Y', //local
      stripeKey: 'pk_live_51NDWEFGk2JcBer3fjypYADcBqyqW3eHdD2bWaROht1ONkxdttmL5yv7RjXjaOQhEowEsKbclSaJ8z3TzKqMK7W8h00lRjdHaxJ', 
      stripe: null,
      cardElement: null,
    };
  },
  mounted() {
      const stripeRef = window.Stripe
      this.stripe = stripeRef(this.stripeKey)
      const elements = this.stripe.elements()
      const cardElement = elements.create('card')
      cardElement.mount('#card-element')
      this.cardElement = cardElement
  },
  computed: {
    getCardType() {
      let number = this.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp("^9792");
      if (number.match(re) != null) return "troy";

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },
  },
  watch: {
    cardYear() {
      if (this.cardMonth < this.minCardMonth) {
        this.cardMonth = "";
      }
    },
  },
  methods: {
    async StripeAddPaymentMethod(){
      const {paymentMethod,error} = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
        
      });
      if(!error){
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.accessToken,
          };
        this.axios.post('/user/stripe/addPaymentMethod', {id: paymentMethod.id}, {headers: headers})
        .then(this.$root.$emit("closeCard"))
      }
    },
    tokenize() {
      window.Conekta.setPublicKey(process.env.VUE_APP_CONEKTA_API_KEY);
      const tokenParams = {
        card: {
          number: this.cardNumber,
          name: this.cardName,
          exp_year: this.cardYear,
          exp_month: this.cardMonth,
          cvc: this.cardCvv,
        },
      };
      window.Conekta.Token.create(
        tokenParams,
        (token) => {
          var headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.accessToken,
          };
          const paymentToken = {
            paymentToken: token.id,
          };
          this.addingPayment = true;
          this.axios
            .post("/user/payment/create", paymentToken, { headers: headers })
            .then(() => {
              this.addingPayment = false;
              this.$root.$emit("closeCard");
            })
        },
        (error) => {
          console.log(error);
        }
      );
    },
    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      console.log(e)
      /*this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
      };*/
    },
  },
};
</script>
