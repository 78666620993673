<template>
  <div class="container" style="margin-top:200px;">
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-media src="src" height="200px">
          </v-card-media>
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Carrito de compras</h3>
              <div>Cursos Zertu</div>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn flat color="primary" @click="$router.push('/carrito')">Ir a mi carrito de Cursos Zertú</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-media src="src" height="200px">
          </v-card-media>
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Carrito de compras</h3>
              <div>E-commerce</div>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn flat color="primary" @click="$router.push('/carrito-tienda')">Ir a mi carrito de E-commerce Zertú</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>      
      <v-col cols="12" md="6">
        <v-card>
          <v-card-media src="src" height="200px">
          </v-card-media>
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Carrito de compras</h3>
              <div>Certificaciones</div>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn flat color="primary" @click="$router.push('/carrito-certificaciones')">Ir a mi carrito de Certificaciones</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {

};
</script>
