var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card text-left",staticStyle:{"padding":"30px","border":"1px solid #ccc"}},[_c('div',{staticClass:"card-img-top",style:({
      backgroundImage: 'url(' + _vm.product.images[0].src + ')',
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
      width: '100%',
      paddingTop: '100%',
      position: 'relative',
      backgroundPosition: 'center center',
    })}),_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.product.name))]),(
        _vm.product.regular_price == _vm.product.sale_price ||
        _vm.product.sale_price == ''
      )?_c('p',{staticClass:"card-text"},[_vm._v(" $ "+_vm._s(_vm.product.regular_price)+" ")]):_vm._e(),(
        _vm.product.regular_price != _vm.product.sale_price &&
        _vm.product.sale_price != ''
      )?_c('p',{staticClass:"card-text",staticStyle:{"text-decoration":"line-through"}},[_vm._v(" Precio regular: $"+_vm._s(_vm.product.regular_price)+" ")]):_vm._e(),(
        _vm.product.regular_price != _vm.product.sale_price &&
        _vm.product.sale_price != ''
      )?_c('p',{staticClass:"card-text"},[_vm._v(" Promoción: $"+_vm._s(_vm.product.sale_price)+" ")]):_vm._e(),_c('v-btn',{staticClass:"btn btn-primary",staticStyle:{"width":"100%"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToProduct(_vm.product)}}},[_vm._v("Ver Producto")]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"40px"}},[_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"label":"Cantidad","type":"number","min":"1","inline":"","center":"","controls":""},model:{value:(_vm.product.quantity),callback:function ($$v) {_vm.$set(_vm.product, "quantity", $$v)},expression:"product.quantity"}})],1),_c('div',{staticClass:"col-12"},[_c('v-btn',{staticClass:"btn btn-primary",staticStyle:{"width":"100%"},attrs:{"color":"primary"},on:{"click":_vm.addToCart}},[_vm._v(" Agregar al carrito ")])],1)])],1),_c('v-snackbar',{attrs:{"absolute":"","bottom":"","color":"success","outlined":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Ok ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Producto agregado ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }