<template>
  <div style="margin-top: 100px">
    <div
      v-if="loading"
      style="
        margin-top: 100px;
        justify-content: center;
        align-items: center;
        display: flex;
      "
    >
      <v-progress-circular
        :size="50"
        color="turquoise"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <v-row>
        <v-col cols="12" md="6" v-if="certificaciones.promoUrl">
          <h2 style="text-align: center">Video promocional</h2>
          <vimeo-player
            style="border-radiux: 30px;"
            ref="player"
            :video-url="certificaciones.promoUrl"
          />
        </v-col>
        <v-col md="6" cols="12" sm="12"
          ><h2>{{ certificaciones.nombre }}</h2>
          <v-row
            v-if="certificaciones.pdfUrl"
            style="margin-top:50px;margin-bottom:50px"
          >
            <v-btn
              :href="certificaciones.pdfUrl"
              target="_blank"
              color="primary"
              >Descargar PDF</v-btn
            >
          </v-row>
          <p v-html="certificaciones.descripcion" style="margin-top: 30px"></p>
          <div v-if="!certificaciones.pagada">
            <p style="font-size: 20px">
              Precio: {{ certificaciones.precio | toCurrency}}<br />
            </p>
            <v-btn
              @click="$root.$emit('openLogin')"
              elevation="2"
              depressed
              dark
              color="turquoise"
              v-if="!$store.state.accessToken"
            >
              Iniciar sesión</v-btn
            >

            <v-row
              v-if="
                $store.state.accessToken && certificaciones.available_purchase
              "
            >
              <v-col cols="12" md="4">
                <h2>Forma de pago</h2>
                <div v-if="!loadingPaymentMethods">
                  <v-select
                    v-model="installments"
                    :items="availableInstallments"
                    item-text="nombre"
                    attach
                    outlined
                    return-object
                    no-data-text="Tarjeta no disponible"
                    label="Selecciona el plazo de pago"
                  >
                    <template v-slot:item="data">
                      <v-row>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.nombre"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-row>
                    </template>
                  </v-select>
                  <v-select
                    v-model="cardSelected"
                    :items="paymentMethods"
                    attach
                    outlined
                    item-text="maskedNumber"
                    return-object
                    label="Selecciona la tarjeta o crea una nueva"
                  ></v-select>
                </div>
                <v-btn
                  elevation="2"
                  depressed
                  dark
                  class="mt-2"
                  style="width: 100%;"
                  color="turquoise"
                  @click="pagar(cardSelected.id)"
                  >Pagar</v-btn
                >
                <p v-if="errorPago">
                  Ocurrió un problema con tu método de pago, por favor intenta
                  con otro
                </p>
                <!-- :amount="String(course.price)"-->

                <v-btn
                  elevation="2"
                  class="mt-2"
                  style="width: 100%;"
                  depressed
                  dark
                  color="turquoise"
                  @click="showNewMethod = true"
                  >Agregar un método de pago</v-btn
                >
                <v-btn
                  v-on:click="addToCart"
                  class="mt-2"
                  dark
                  color="turquoise"
                  style="width: 100%"
                >
                  Agregar al carrito
                </v-btn>
                <v-btn
                  class="mt-2"
                  style="width: 100%;"
                  elevation="2"
                  depressed
                  dark
                  color="turquoise"
                  @click="payOxxo()"
                  >Pagar en oxxo
                </v-btn>
                <PayPal
                  style="position: relative; z-index: 1;"
                  :amount="String(certificaciones.precio)"
                  currency="MXN"
                  class="mt-2"
                  v-on:payment-authorized="paymentAuthorized"
                  v-on:payment-completed="paymentCompleted"
                  v-on:payment-cancelled="paymentCancelled"
                  :client="paypal"
                  :button-style="myStyle"
                ></PayPal>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              v-else-if="!certificaciones.available_purchase"
            >
              <v-col cols="12" align="left" justify="center"
                ><b>Próximamente fechas para 2024</b></v-col
              >
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="certificaciones.pagada">
        <v-col cols="12" md="8">
          <v-timeline clipped :reverse="false" dense>
            <v-timeline-item
              color="purple lighten-2"
              fill-dot
              right
              v-for="(modulo, index) in certificaciones.modulos"
              v-bind:key="modulo.id"
            >
              <template v-slot:icon>
                <p
                  style="
                    color: white;
                    font-size: 30px !important;
                    font-weight: bold;
                    margin-top: 15px;
                  "
                >
                  {{
                    modulo.titulo.includes("Introducción")
                      ? 0
                      : modulo.titulo.replace("MÓDULO", "").trim()
                  }}
                </p>
              </template>
              <v-card>
                <v-card-title class="purple lighten-2">
                  <v-icon dark size="42" class="mr-4"> mdi-magnify </v-icon>
                  <h2
                    style="font-size: 20px !important"
                    class="text-h4 white--text font-weight-light"
                  >
                    {{ modulo.nombre }}
                  </h2>
                </v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10"> </v-col>
                    <div style="padding: 30px">
                      <div v-html="modulo.descripcion"></div>
                      <v-btn
                        v-if="
                          modulo.active ||
                            (certificaciones.modulos[index - 1] &&
                              certificaciones.modulos[index - 1].aprobado ==
                                true)
                        "
                        dark
                        class="purple"
                        style="margin-top: 30px"
                        @click="$router.push('/modulo/' + modulo.id)"
                      >
                        Ver Módulo
                      </v-btn>

                      <v-btn
                        :disabled="true"
                        v-else-if="
                          !modulo.active ||
                            (certificaciones.modulos[index - 1] &&
                              certificaciones.modulos[index - 1].aprobado ==
                                false)
                        "
                        light
                        class="purple"
                        style="margin-top: 30px"
                        >Debes aprobar el módulo anterior</v-btn
                      >
                    </div>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="pagando" width="500">
      <v-card :loading="pagando">
        <v-card-title class="text-h5 grey lighten-2">
          Procesando Pago
        </v-card-title>

        <v-card-text style="text-align:center;font-size:20px;margin-top:20px;">
          Estamos procesando tu pago, por favor espera
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showError" max-width="400">
      <v-card>
        <v-card-text>
          <p
            class="error--text"
            style="font-size: 20px; text-align: center; padding-top: 20px"
          >
            {{
              errorMessage
                ? errorMessage
                : "El producto ya se encuentra agregado al carrito"
            }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="500"
      v-model="errorPago"
    >
      <v-card>
        <v-card-title
          color="red"
          style="color:white;background-color:red!important;"
          class="text-h5 grey lighten-2"
        >
          Error al procesar tu pago
        </v-card-title>
        <v-card-text style="text-align:center;font-size:20px;margin-top:20px;">
          Por favor intenta con otro método de pago
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="errorPago = false">Reintentar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog overlay fullscreen v-model="showNewMethod">
      <CreditCard :cerrarFormaPago="cerrarFormaPago"></CreditCard>
    </v-dialog>
    <v-snackbar v-model="snackbar" absolute bottom color="success" outlined>
      Cerficicacion agregado al carrito
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import CreditCard from "../components/CreditCard.vue";
import PayPal from "vue-paypal-checkout";
import { structOrder } from "../helpers/paymentOrderHelpers";
export default {
  components: {
    CreditCard,
    PayPal,
  },
  data() {
    return {
      snackbar: false,
      isAvailableMasterIntro: false,
      availableDateMasterIntro: new Date("2023/05/13 00:00:00"),
      installments: {
        nombre: "Pago de contado",
        installments: 1,
      },
      availableInstallments: [],
      cardSelected: null,
      showNewMethod: false,
      loading: true,
      pagando: false,
      certificaciones: {},
      paymentMethods: [],
      errorPago: false,
      showError: null,
      errorMessage: null,
      loadingPaymentMethods: true,
      myStyle: {
        label: "checkout",
        size: "responsive",
        shape: "rect",
        color: "gold",
      },
    };
  },
  mounted() {
    this.obtenerCertificaciones();
    this.CheckAvailable();
    this.getPaymentMethodList();
    this.$root.$on("closeCard", () => {
      this.showNewMethod = false;
      this.getPaymentMethodList();
    });
    if(this.cardSelected) this.getPlans(this.cardSelected.id)
    setInterval(() => {
      this.CheckAvailable();
    }, 10000);
  },
  watch:{
    cardSelected(value){
      if(value) this.getPlans(value.id)  
    }
  },
  methods: {
    CheckAvailable() {
      const date = new Date();
      if (date > this.availableDateMasterIntro)
        this.isAvailableMasterIntro = true;
    },
    getPlans(token){
      var request = {
        paymentMethodId: token,
        certificationList: [this.certificaciones],
        installments: this.installments.installments
      }; 
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios.post('/cart/stripe/get-plans', request, {
        headers:headers
      })
      .then(res=> {
        this.intentId = res.data.intent_id
        this.activePlans =  res.data.available_plans
        this.availableInstallments = this.activePlans.map(x=> {
         return {nombre: `${x.count} Meses sin intereses`, installments: x.count}
        })
        this.availableInstallments.unshift({nombre: 'Pago a contado', installments:1})
      })
      .catch(e=>{
        console.log(e)
      })
    },
    generarCertificado() {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
        responseType: "blob",
      };
      this.axios
        .get(
          "/certificacion/certificado/generar/" + this.modulo.certificacionId,
          { headers: headers }
        )
        .then(function(response) {
          let binaryString = window.atob(response);
          let binaryLen = binaryString.length;
          let bytes = new Uint8Array(binaryLen);

          for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }

          let blob = new Blob([bytes], { type: "application/pdf" });
          let link = URL.createObjectURL(blob);
          window.open(link, "_blank");
        });
    },
    addToCart() {
      const cart = this.$store.state.cartCertifications;
      if (cart.find((x) => x.id === this.certificaciones.id)) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 2000);
      } else {
        this.$store.commit("addCertificationToCart", this.certificaciones);
        this.snackbar = true;
      }
    },
    paymentAuthorized: function(data) {
      console.log(data);
    },
    paymentCompleted: function(data) {
      this.payPaypal(data.id);
    },
    paymentCancelled: function(data) {
      console.log(data);
      this.paymentError = true;
    },
    payPaypal(orderId) {
      const certificaciones = [];
      certificaciones.push(this.certificaciones);
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/certificacion/paypal/pay/" + orderId, certificaciones, {
          headers: headers,
        })
        .then((response) => {
          this.paying = false;
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCart");
            this.pagando = false;
            this.obtenerCertificaciones();
          }
        })
        .catch((e) => {
          if (e.response.status === 401)
            this.$store.dispatch("LogoutException");
        });
    },
    payOxxo() {
      const certificaciones = [];
      certificaciones.push(this.certificaciones);
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/certificacion/conekta/pay-oxxo", certificaciones, {
          headers: headers,
        })
        .then((response) => {
          this.paying = false;
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCart");
            this.axios
              .get(
                `/certificacion/payments-by-order/${response.data.response.ticket.receipt}`
              )
              .then((res) => {
                this.$store.commit("setPaymentReference", null);
                const order = structOrder(res.data);
                this.$store.commit("setPaymentReference", order[0]);
                this.$router.push("/payment-reference");
              });
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            this.$store.dispatch("LogoutException");
          }

          this.paying = false;
          this.errorMessage = e.response.data.message;
          this.showError = true;
          this.showPaying = false;
          setTimeout(() => {
            this.showError = false;
            this.errorMessage = null;
          }, 2000);
        });
    },
    obtenerCertificaciones() {
      if (this.$store.state.accessToken != null) {
        var headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.accessToken,
        };
        this.axios
          .get("/certificacion/slug/" + this.$route.params.slug, {
            headers: headers,
          })
          .then((response) => {
            this.loading = false;
            this.certificaciones = response.data.response;
            this.$store.commit("setCertifications", response.data.response);
          });
      } else {
        this.axios
          .get("/certificacion/slug/" + this.$route.params.slug)
          .then((response) => {
            this.loading = false;
            this.certificaciones = response.data.response;
          });
      }
    },
    getPaymentMethodList() {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .get("/user/stripe/payment/list", { headers: headers })
        .then((response) => {
          this.loadingPaymentMethods = false;
          this.paymentMethods = response.data.response;
          this.paymentMethods.forEach((element) => {
            if (element.defaultMethod) {
              this.cardSelected = element;
            }
          });
        })
        .catch((e) => {
          if (e.response.status === 401) console.log("Unauthorized");
          //this.$store.dispatch('LogoutException')
        });
    },
    pagar(token) {
      this.pagando = true;
      
      /*
      if (localStorage.referencia) {
        request.referencia = localStorage.referencia;
      }
      */
      var vm = this;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      vm.paying = true;
      vm.errorPago = false;
      
      const request = {
          paymentMethodId: token,
          certificationList: [this.certificaciones],
          installments: this.installments.installments
      };
      if(this.installments.installments > 1){
        const plan = this.activePlans.find(x=> x.count === this.installments.installments)
        request.payment_intent_id = this.intentId
        request.selected_plan = plan
      }
        this.axios
          .post("/cart/stripe/pay-certifications", request, { headers: headers })
          .then(function(response) {
            vm.paying = false;
            console.log(response);
            if (response.data.code == 200) {
              vm.pagando = false;
              vm.$store.commit("emptyCertCart");
              vm.$router.push({
                name: "graciasCertificaciones",
              });
            } else {
              vm.errorPago = true;
              vm.pagando = false;
            }
          });
      
        
    },
    cerrarFormaPago() {
      this.showNewMethod = false;
    },
  },
  computed: {
    ...mapState(["paypal"]),
  },
};
</script>
