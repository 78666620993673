<template>
  <div class="container" style="margin-top: 200px">
    <cart></cart>
    <div class="row">
      <div class="col-md-12">
        <h2 style="text-align: center">{{ product.name }}</h2>
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <img style="width: 100%" :src="product.images[0].src" />
          </div>
          <div class="col-md-6">
            <div style="padding: 30px">
              <p v-html="product.description"></p>
              <p v-html="product.short_description"></p>
              <p
                class="card-text"
                style="font-size:30px;"
                v-if="
                  product.regular_price == product.sale_price ||
                  product.sale_price == ''
                "
              >
                $ {{ product.regular_price }}
              </p>
              <p
                class="card-text"
                style="text-decoration: line-through;font-size:30px;"
                
                v-if="
                  product.regular_price != product.sale_price &&
                  product.sale_price != ''
                "
              >
                Precio regular: ${{ product.regular_price }}
              </p>
              <p
                class="card-text"
                style="font-size:30px;"
                
                v-if="
                  product.regular_price != product.sale_price &&
                  product.sale_price != ''
                "
              >
                Promoción: ${{ product.sale_price }}
              </p>
              <p>{{product.stock_quantity}} disponibles</p>
            </div>
            <div class="row" style="margin-top: 40px">
              <div class="col-12">
                <v-text-field
                    label="Cantidad"
                    type="number"
                    min="0"
                    v-model="product.quantity"
                    inline
                    center
                    controls
                ></v-text-field>
              </div>
              <div class="col-12">
                <v-btn
                  v-on:click="addToCart"
                  color="primary"
                  style="width: 100%"
                >
                  Agregar al carrito
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
      product:null
    }
  },
  mounted(){
    this.fetchProduct(this.$route.params.id)
  },
  methods: {
    fetchProduct(id){
      this.axios.get(`/store/product/${id}`)
      .then(res=>{
        this.product = res.data.response
      })
    },
    goToProduct(product) {
        console.log(product);
    },
    goToCart() {},
    addToCart() {
        this.$store.commit("addItemToEcommerceCart", this.product);
        this.snackbar = true;
    },
  },
};
</script>
