<template>
  <div style="margin-top: 100px">
    <v-row>
      <v-col md="6" offset-md="3">
        <v-row>
          <v-col cols="12">
            <h1>Mi perfil</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>
              Tu correo registrado es: <b>{{ profile.username }}</b>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-form ref="formName">
              <v-text-field
                type="text"
                name="input-10-2"
                label="Nombre"
                outlined
                :rules="[rules.required]"
                v-model="profile.name"
              ></v-text-field>
            </v-form>
            <v-form ref="form" v-if="false">
              <v-text-field
                type="text"
                name="input-10-2"
                label="Whatsapp"
                outlined
                v-model="profile.whatsapp"
              ></v-text-field>
              <p>Si deseas actualizar tu contraseña, escribe tu contraseña actual y la nueva contraseña que deseas, si no deseas actualizar tu contraseña deja los campos en blanco</p>
              <v-text-field
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                name="input-10-2"
                value="wqfasds"
                class="input-group--focused"
                @click:append="show3 = !show3"
                outlined
                label="Contraseña actual"
                v-model="password"
              ></v-text-field>
              <v-text-field
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                name="input-10-2"
                value="wqfasds"
                class="input-group--focused"
                @click:append="show3 = !show3"
                outlined
                label="Nueva contraseña"
                v-model="newPassword"
              ></v-text-field>
              <v-text-field
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                name="input-10-2"
                value="wqfasds"
                class="input-group--focused"
                @click:append="show3 = !show3"
                outlined
                label="Confirmación de contraseña nueva"
                :rules="[rules.required, passwordConfirmationRule]"
                v-model="passwordConfirm"
              ></v-text-field>
              <v-btn
                elevation="2"
                depressed
                dark
                style="width: 100%; margin-top: 15px"
                color="turquoise"
                @click="actualizar()"
                v-if="!actualizando"
                >Guardar</v-btn
              >
              <v-btn
                elevation="2"
                depressed
                light
                disabled
                style="width: 100%; margin-top: 15px"
                color="turquoise"
                v-if="actualizando"
                >Guardando</v-btn
              >
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" absolute bottom color="success" outlined>
      Perfil actualizado

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      profile: null,
      snackbar:false,
      show1: false,
      show2: false,
      show3: false,
      password: null,
      passwordConfirm: null,
      newPassword: null,
      actualizando: false,
      rules: {
        required: (value) => !!value || "Requerido",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no válido.";
        },
        confirmPasswordRules: [
          (value) => !!value || "type confirm password",
          (value) =>
            value === this.passwordLogin ||
            "The password confirmation does not match.",
        ],
      },
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.passwordConfirm ||
        "Los passwords no coinciden";
    },
  },

  mounted() {
    var headers = {};
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.$store.state.accessToken,
    };
    this.axios.get("/user/profile", { headers: headers }).then((response2) => {
      console.log(response2.data.response.name);
      this.profile = response2.data.response;
    });
  },
  methods: {
    actualizar() {
      let puedeActualizar = true;
      if (this.newPassword != null) {
        puedeActualizar = this.$refs.form.validate();
      }
      if(puedeActualizar==true){
        puedeActualizar=this.$refs.formName.validate();
      }
      if (puedeActualizar) {
        this.actualizando = true;
        let req = {
          name: this.profile.name,
          newPassword: this.newPassword,
          whatsapp: this.profile.whatsapp,
        };
        var headers = {};
        headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.accessToken,
        };
        this.axios
          .post("/user/profile/update", req, { headers: headers })
          .then((response2) => {
            console.log(response2.data.response);
            this.actualizando = false;
            this.snackbar=true;
          });
      }
    },
  },
};
</script>
