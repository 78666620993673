<template>
    <div class="container" style="margin-top:200px;">
        <h1>Términos y condiciones</h1>
        <p>Los Términos y Condiciones del presente documento y demás políticas y principios que lo rigen se aplican al uso de servicios ofrecidos por ZTU, S.C. (en adelante “ZERTÚ”), con domicilio en Bosque de Olmos No. 51, Colonia Bosques de las Lomas, delegación Cuajimalpa de Morelos, Ciudad de México, CP 05120. Asimismo el correo electrónico para todos los servicios relacionados es el contact@zertu.mx. Cualquier persona física y/o moral que participe y/o utilice los servicios ofrecidos por ZERTÚ será considerara en lo sucesivo como el Usuario.</p>
<p>La lectura de estos Términos y Condiciones es indispensable para que el Usuario pueda disfrutar adecuadamente de los servicios que ofrece ZERTÚ, así como para la exacta comprensión y uso y aprovechamiento de los servicios.</p>
<p><strong>1. Aceptación de los Términos y Condiciones.</strong></p>
<p>Al participar y aprovechar los servicios y/o actividades, el Usuario acepta las disposiciones de estos Términos y Condiciones. En caso que el Usuario no requiera someterse a estos Términos y Condiciones, no estará autorizado en participar y aprovechar los servicios y/o actividades que ofrece ZERTÚ. De ser el caso, aconsejamos interrumpir cualquier actividad y sus servicios. ZERTÚ podrá, en cualquier momento y a su exclusiva discreción, modificar estos Términos y Condiciones, siendo las condiciones aplicables a aquellas en vigor en los datos de registro realizados por el Usuario.</p>
<p>Los presentes Términos y Condiciones, en su versión más actualizada, estarán permanentemente disponibles en el domicilio de ZERTÚ y en su caso en la página de internet http://www.zertu.mx/</p>
<p><strong>2. Descripción de los servicios.</strong></p>
<p><strong>2.1. Servicios ofrecidos.</strong></p>
<p>ZERTÚ presta a sus Usuarios, los siguientes servicios, sin perjuicio de otros que puedan ser introducidos oportunamente en estos Términos y Condiciones:</p>
<p>• Un servicio para cursos presenciales y demás productos relativos a actividades como meditación, yoga meditativa y cocina meditativa que se vayan ofreciendo de tiempo en tiempo.</p>
<p>• Un servicio para curso presencial y demás productos relativos a las actividades y servicios que ofrece ZERTÚ de manera presencial, descritas en la página de internet http://www.zertu.mx/</p>
<p>Con este fin, el Usuario tendrá acceso, según el curso, a actividades como meditación, yoga meditativa y cocina meditativa.</p>
<p>La descripción de los servicios ofrecidos no es exhaustiva ni tampoco definitiva, por lo que ZERTÚ se reserva el derecho de (i) modificar las modalidades implementadas por los servicios y/o (ii) incrementar los servicios o nuevas características, buscando mejorar la calidad de los servicios ofrecidos.</p>
<p><strong>2.2. Precio de los servicios para cursos presenciales.</strong></p>
<p>El precio de los servicios ofrecidos por ZERTÚ estará disponible a través de su página de internet http://www.zertu.mx/ o en lugar donde se lleguen a prestar dichos cursos presenciales.</p>
<p><strong>2.3. Participación voluntaria.</strong></p>
<p>El Usuario participa de manera voluntaria y bajo sus propios riesgos, en los servicios que ZERTÚ ofrece, por lo que el Usuario asume todos los riesgos con relación a su participación en los servicios y actividades que ZERTÚ proporciona.</p>
<p><strong>3. Requisitos necesarios para los servicios.</strong></p>
<p>Para poderse beneficiar de los Servicios, el Usuario debe disponer de (i) buena salud física y mental, y (ii) no tener impedimento alguno para realizar dichas actividades.</p>
<p>Para participar en los cursos presenciales ZERTÚ, es necesario registrarse proporcionando en su caso nombre completo, dirección de correo electrónico, entre otra información personal (fecha de nacimiento, edad, estado civil, sexo, teléfono, domicilio) y consecuentemente realizar el pago del valor establecido para los servicios que ofrece ZERTÚ. Los datos que se proporcionen al efecto serán confidenciales y no podrán ser divulgados a terceros.</p>
<p>El Usuario participa y aprovecha los servicios y/o actividades de manera presencial. La condición para el uso de todo el contenido es la aceptación tácita de los Términos y Condiciones.</p>
<p>Los servicios no representan para el Usuario ninguna garantía de resultado. Los resultados obtenidos pueden variar en función de varios factores, incluso de individuo a individuo</p>
<p><strong>4. Pagos y devoluciones.</strong></p>
<p><strong>4.1. Cargos automáticos.</strong></p>
<p>El servicio de ZERTÚ es uno pagado. El Usuario está de acuerdo y aprueba dichos cargos por los servicios y acepta pagar los cargos hechos a su cuenta asociada para estos fines.</p>
<p>El Usuario será sujeto de cargos de acuerdo a los términos aceptados al momento de la aceptación del curso presencial.</p>
<p>Al suscribirse a ZERTÚ, el Usuario acepta los cargos hechos, incluyendo los que sean a su tarjeta o medio de pago. El Usuario acepta que no habrá reembolsos.</p>
<p><strong>4.2. Autorización.</strong></p>
<p>Cuando el Usuario solicita los servicios y proporciona un método de pago a ZERTÚ, está expresando su aprobación y autorización expresa para que ZERTÚ (y su procesador de pagos) realicen los cargos que se requieran. Si el Usuario proporciona un método de pago y el cargo resulta en comisiones, multas o penalizaciones por falta de fondos u otros, el Usuario será el responsable por ellos.</p>
<p>En caso que el Usuario no utilice los servicios que ZERTÚ ofrece, el Usuario será responsable por el servicio solicitado.</p>
<p><strong>4.3. Cambios.</strong></p>
<p>ZERTÚ puede incrementar sus cuotas y/o servicios de cursos presenciales. El Usuario acepta las nuevas cuotas de los servicios ofrecidos por ZERTÚ.</p>
<p>La aceptación de ZERTÚ y la continuidad del acceso a los servicios que ofrece estarán siempre sujetas a los pagos realizados por el Usuario. ZERTÚ se reserva el derecho de suspender los servicios si el Usuario no realiza el pago de las cuotas que obren a su cargo.</p>
<p>Para las transacciones que ZERTÚ requiera realizar por los servicios que ofrece, podrá utilizar la plataforma de pago electrónica o un servicio similar para la mayoría de las transacciones o cualquier otro que designen las partes. ZERTÚ no tiene acceso a la información de la tarjeta de crédito, débito o cualquier otro medio financiero del Usuario, por lo que no será responsable de ninguna pérdida en que el Usuario incurra como consecuencia del uso de este método de pago, a menos que sea causado por ZERTÚ o alguno de sus empleados.</p>
<p><strong>4.4. Reembolsos.</strong></p>
<p>Los pagos que el Usuario pague por concepto de los servicios que ZERTÚ ofrece no serán reembolsables, a menos que no se preste el servicio por causas imputables a ZERTÚ.</p>
<p>Los pagos por los servicios que ZERTÚ ofrece no son cancelables ni reembolsables, salvo lo dispuesto expresamente en estos Términos y Condiciones.</p>
<p><strong>5. Obligaciones de los Usuarios.</strong></p>
<p>El Usuario se compromete a notificar a ZERTÚ los elementos, datos e información correcta para el procesamiento de su solicitud y la prestación de los servicios que ZERTÚ ofrece.</p>
<p>El Usuario es completamente responsable de las consecuencias derivadas de la transmisión de información falsa, incorrecta o imprecisa.</p>
<p>Todos los datos e información del Usuario deberán ser actualizados en el registro que lleva ZERTÚ siempre que haya algún cambio.</p>
<p>El Usuario se compromete a no revender y a no utilizar los servicios de ZERTÚ con cualquier finalidad comercial. Asimismo se compromete a no utilizar sus servicios con finalidades ilegales, contrarias al orden público y a las buenas costumbres, con propósitos difamatorios o conteniendo elementos que atenten contra la vida privada de terceros, así como a no transmitir información que pueda causar cualquier daño a los derechos de ZERTÚ y/o de terceros.</p>
<p>El Usuario se compromete a garantizar e indemnizar a ZERTÚ en caso de cualquier daño, demanda o reclamación por parte de terceros, que resulten del mal manejo por parte del Usuario de los servicios que ZERTÚ ofrece, o bien, la violación a estos Términos y condiciones o a los derechos de terceros.</p>
<p>El nombre de Usuario registrado para el uso de los servicios es responsabilidad exclusiva del Usuario, por lo que este se obliga a responder por cualquier acto realizado por terceros que resulten en conductas ilegales o indebidas a través de los datos registrados.</p>
<p>Enunciativa más no limitativamente, el Usuario se compromete y obliga a no: (i) compartir el contenido de los servicios, (ii) publicar o difundir, directa o indirectamente, cualquiera de los contenidos de ZERTÚ (cursos, actividades, recetas, programas, etc.) en cualquier medio y sitios web, incluyendo páginas o sitios web de medios sociales, (iii) usar el logotipo, marcas o cualquier derecho de propiedad intelectual de ZERTÚ, idénticas o similares en grado de confusión para describir el mercado o promocionar cualquier producto o servicio de ZERTÚ, y (iv) llevar a cabo o participar directa o indirectamente en el registro o el mantenimiento de páginas web o páginas en redes sociales que tergiversen una afiliación con ZERTÚ y/o Ness Zertú.</p>
<p><strong>6. Advertencias.</strong></p>
<p>Previo a suscribirse a ZERTÚ y realizar cualquiera de las actividades que ZERTÚ ofrece, el Usuario deberá consultar a su médico de cabecera y/o su nutriólogo y buscar el asesoramiento de profesionales de la salud, por lo que el Usuario reconoce y acepta ser el único responsable del consumo que realice respecto de los servicios que ZERTÚ ofrece.</p>
<p>Los servicios no pueden, en caso alguno, sustituir una consulta médica y/o acompañamiento de un nutriólogo profesional. En ningún caso, la información y servicios ofrecidos pueden autorizar el establecimiento del diagnóstico, prescripción o consulta nutricional en lo referente a la salud del Usuario. La información ofrecida jamás sustituirá la consulta de un médico general, especialista, nutriólogo o cualquier otro profesional de la salud.</p>
<p>Las recomendaciones de ZERTÚ no se pueden adaptar a personas que sigan un régimen alimenticio y/o de actividad física en particular, prescrito por un médico o nutriólogo.</p>
<p><strong>7. Responsabilidades.</strong></p>
<p>El Usuario reconoce y acepta que la responsabilidad de ZERTÚ no podrá ser alegada en razón de cualquier inconveniente o daños inherentes al uso de los servicios.</p>
<p>El Usuario acepta que el uso de los servicios que ZERTÚ ofrece, ocurre bajo su exclusiva responsabilidad, control y dirección.</p>
<p><strong>8. Privacidad de la información.</strong></p>
<p>ZERTÚ posee políticas de privacidad de información que definen las reglas aplicadas al tratamiento de la información de registro y de los datos personales del Usuario. Estas políticas de privacidad se encuentran ubicadas en el lugar donde se prestan los servicios, así como en la página de internet http://www.zertu.mx/ de internet y deben ser consideradas parte integral de los presentes Términos y Condiciones, siendo obligatoria su lectura por parte del Usuario previo al uso de los servicios que ofrece ZERTÚ.</p>
<p><strong>9. Propiedad intelectual.</strong></p>
<p>El Usuario reconoce y acepta que el contenido de los servicios que ZERTÚ ofrece, incluyendo enunciativa más no limitativamente, textos, fotografías, sonidos, videos, recetas u otros materiales contenidos en los servicios de ZERTÚ y/o de toda la comunicación dirigida por ZERTÚ, están protegidos por las leyes de los Estados Unidos Mexicanos e internacionalmente por leyes en materia de propiedad intelectual y derechos de autor.</p>
<p>El Usuario no está autorizado a copiar, utilizar, reproducir, distribuir o crear obras derivadas del contenido de los servicios que ZERTÚ ofrece.</p>
<p>Los Usuarios pueden formular comentarios y observaciones referentes a los servicios. El contenido de cualquier retorno de información a ZERTÚ será considerado como no confidencial y exento de derechos. ZERTÚ podrá utilizar libremente las ideas, conceptos, conocimiento o técnicas contenidas en estas comunicaciones para todos los efectos, sin restricción alguna, con el fin de reproducir y divulgar cualquier información a terceros, sin que sea atribuido al Usuario cualquier derecho de la naturaleza que fuese.</p>
<p>La reproducción o el uso, total o parcial de cualquier material, está únicamente autorizado para fines exclusivos de información para uso personal y privado, estando expresamente prohibida la reproducción y todas las copias realizadas y cualquier material contenido en el mismo, con otra finalidad. Cualquier uso diverso y no autorizado previamente por ZERTÚ, será considerado como una infracción, debiendo ser penalizado de conformidad con las leyes aplicables.</p>
<p><strong>10. Servicio de atención al cliente.</strong></p>
<p>El servicio de atención al cliente está disponible en (i) la página de internet http://www.zertu.mx/ o (ii) el correo electrónico contact@zertu.mx.</p>
<p><strong>11. Acuerdos.</strong></p>
<p>Los presentes Términos y Condiciones constituyen el acuerdo firmado entre ZERTÚ y el Usuario, referente al uso de los servicios, sustituyendo a cualquier acuerdo referente al mismo objeto y anteriormente firmado por el Usuario y ZERTÚ. El Usuario reconoce que este instrumento no contiene solicitud, promoción o indicación de cualquier producto no permitido por las leyes de los Estados Unidos Mexicanos.</p>
<p>En caso que el Usuario no esté de acuerdo con los términos de este documento, deberá notificar por escrito a ZERTÚ su inconformidad al mismo, razón por la cual en ese momento se cancelará su acceso a los servicios ofrecidos por ZERTÚ.</p>
<p><strong>12. Modificaciones.</strong></p>
<p>ZERTÚ se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento y sin previo aviso, publicando la enmienda a los Términos y Condiciones de Uso que siempre estará accesible en el lugar donde se presten sus servicios y en su caso en la parte inferior de la página de internet http://www.zertu.mx/. El Usuario acepta y reconoce que el uso continuo de los servicios después de una modificación a los Términos y Condiciones constituye su aceptación expresa y vinculante a la modificación de dichos Términos y Condiciones.</p>
<p><strong>13. Invalidez.</strong></p>
<p>En caso que una de las estipulaciones de estos Términos y Condiciones sea considerara ilícita o inaplicable por cualquier tribunal competente, será considerada como inválida o suprimida, no afectando la validez de las demás estipulaciones de estos Términos y Condiciones.</p>
<p><strong>14. Encabezados.</strong></p>
<p>Los encabezados utilizados en estos Términos y Condiciones han sido incluidos por conveniencia únicamente y no afectarán el significado o la interpretación de cualquier disposición de los mismos.</p>
<p><strong>15. Jurisdicción y ley aplicable.</strong></p>
<p>Los presentes Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de los Estados Unidos Mexicanos. Para cualquier controversia que surja con relación a estos Términos y Condiciones, el Usuario y ZERTÚ en este acto se someten expresa e irrevocablemente a la jurisdicción exclusiva de los tribunales competentes de la Ciudad de México y por lo tanto, renuncian expresa e irrevocablemente a cualquier otra jurisdicción que les pueda corresponder por sus domicilios presentes o futuros, o por cualquier otra razón.</p>
    </div>
</template>

<script>
export default {

}
</script>
