var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",staticStyle:{"margin-top":"200px"}},[_c('cart'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.product.name))]),_c('hr')])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.product.images[0].src}})]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticStyle:{"padding":"30px"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.product.description)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.product.short_description)}}),(
                _vm.product.regular_price == _vm.product.sale_price ||
                _vm.product.sale_price == ''
              )?_c('p',{staticClass:"card-text",staticStyle:{"font-size":"30px"}},[_vm._v(" $ "+_vm._s(_vm.product.regular_price)+" ")]):_vm._e(),(
                _vm.product.regular_price != _vm.product.sale_price &&
                _vm.product.sale_price != ''
              )?_c('p',{staticClass:"card-text",staticStyle:{"text-decoration":"line-through","font-size":"30px"}},[_vm._v(" Precio regular: $"+_vm._s(_vm.product.regular_price)+" ")]):_vm._e(),(
                _vm.product.regular_price != _vm.product.sale_price &&
                _vm.product.sale_price != ''
              )?_c('p',{staticClass:"card-text",staticStyle:{"font-size":"30px"}},[_vm._v(" Promoción: $"+_vm._s(_vm.product.sale_price)+" ")]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.product.stock_quantity)+" disponibles")])]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"40px"}},[_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"label":"Cantidad","type":"number","min":"0","inline":"","center":"","controls":""},model:{value:(_vm.product.quantity),callback:function ($$v) {_vm.$set(_vm.product, "quantity", $$v)},expression:"product.quantity"}})],1),_c('div',{staticClass:"col-12"},[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"color":"primary"},on:{"click":_vm.addToCart}},[_vm._v(" Agregar al carrito ")])],1)])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }