<template>
  <div style="margin-top: 100px">
    <v-row v-if="$store.state.cart.length == 0">
      <v-col cols="12">
        <div style="text-align: center; font-size: 30px">
          No tienes cursos en tu carrito
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$store.state.cart.length > 0">
      <v-col cols="12" md="5" offset-md="2">
        <h2>Carrito de compras</h2>
        <div
          v-for="item in $store.state.cart"
          v-bind:key="item.id"
          style="margin-top: 30px"
        >
          <v-row>
            <v-col cols="12" md="3">
              <img
                :src="item.newImageUrl"
                style="width: 100%; border-radius: 10px"
              />
            </v-col>
            <v-col cols="12" md="6">
              <p>{{ item.name }}</p>
              <p>Precio: $ {{ item.price }}</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                dark
                class="red"
                @click="$store.commit('removeCourseFromCart', item)"
                >Eliminar</v-btn
              >
            </v-col>
          </v-row>
          <hr style="margin-top: 10px" />
        </div>
        <p style="font-size: 30px; text-align: right">
          Precio total ${{ price }}
        </p>
      </v-col>
      <v-col cols="12" md="3">
        <h2>Forma de pago</h2>
        <div v-if="!loadingPaymentMethods">
          <v-select
            v-model="cardSelected"
            :items="paymentMethods"
            attach
            outlined
            item-text="maskedNumber"
            return-object
            @change="setDefaultMethod"
            label="Selecciona la tarjeta o crea una nueva"
          ></v-select>
        </div>
        <!-- Important remove env=sandbox on production enviroment-->
        <PayPal
          :amount="String(price)"
          currency="MXN"
          style="width: 100%; margin-top: 30px"
          v-on:payment-authorized="paymentAuthorized"
          v-on:payment-completed="paymentCompleted"
          v-on:payment-cancelled="paymentCancelled"
          :client="paypal"
          :button-style="myStyle"
        ></PayPal>     
        <v-btn
          elevation="2"
          depressed
          dark
          style="width: 100%; margin-top: 30px"
          color="turquoise"
          @click="PayStripe(cardSelected.id)"
          >Pagar</v-btn
        >
        <v-btn
          elevation="2"
          depressed
          dark
          style="width: 100%; margin-top: 30px"
          color="turquoise"
          @click="payOxxo()"
          >Pagar en oxxo</v-btn
        >
        <v-btn
          elevation="2"
          style="width: 100%; margin-top: 30px"
          depressed
          dark
          color="turquoise"
          @click="showNewMethod = true"
          >Agregar tarjeta</v-btn
        >

      </v-col>
    </v-row>
    <v-dialog v-model="showPaying" max-width="400">
      <v-card :loading="paying">
        <v-card-text v-if="!paymentError && paying">
          <p style="font-size: 20px; text-align: center; padding-top: 20px">
            Estamos procesando tu pago por favor espera
          </p>
        </v-card-text>
        <v-card-text v-if="paymentError && !paying">
          <p style="font-size: 20px; text-align: center; padding-top: 20px">
            Ocurrió un problema con tu pago, por favor intenta con otra tarjeta.
          </p>
          <v-btn
            dark
            class="purple"
            @click="showPaying = false"
            style="width: 100%"
            >Intentar con otro método</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showError" max-width="400">
      <v-card >
        <v-card-text >
          <p class="error--text" style="font-size: 20px; text-align: center; padding-top: 20px">
            {{ errorMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog overlay fullscreen v-model="showNewMethod">
      <CreditCard :cerrarFormaPago="cerrarFormaPago"></CreditCard>
    </v-dialog>
  </div>
</template>
<script>
import CreditCard from "../components/CreditCard.vue";
import PayPal from 'vue-paypal-checkout'
import { structOrder } from "../helpers/paymentOrderHelpers";
import { mapState } from "vuex";

export default {
  components: {
    CreditCard,
    PayPal
  },
  computed: {
    price() {
      var price = 0;
      this.$store.state.cart.forEach(
        (element) => (price = price + element.price)
      );
      return price;
    },
    ...mapState(['paypal'])
  },
  mounted() {
    this.getPaymentMethodList();
    this.$root.$on("closeCard", () => {
      this.showNewMethod = false;
      this.getPaymentMethodList();
    });
  },
  data() {
    return {
      myStyle: {
          label: 'checkout',
          size:  'responsive',
          shape: 'pill',
          color: 'gold' 
      },
      showNewMethod: false,
      paymentMethodSelected: null,
      paying: true,
      paymentError: false,
      showPaying: false,
      showError:false,
      loadingPaymentMethods: true,
      paymentMethods: [],
      cardSelected: null,
      errorMessage: '',
    };
  },
  methods: {
    cerrarFormaPago() {
      this.showNewMethod = false;
    },
    paymentAuthorized: function (data) {
      console.log(data);
    },
    paymentCompleted: function (data) {
      console.log(data);
      this.payPaypal(data.id);
    },
    paymentCancelled: function (data) {
      console.log(data);
      this.paymentError = true;
    },
    payPaypal(orderId) {
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/cart/paypal/pay/"+orderId, this.$store.state.cart, { headers: headers })
        .then((response) => {
          this.paying = false;
          console.log(response);
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCart");
            this.$router.push({
              name: "gracias",
              params: { receipt: response.data.response },
            });
          }
        });
    },
    PayStripe(token){
      console.log(token)
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      const request = {
        paymentMethodId: token,
        courseList: this.$store.state.cart,
     
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/cart/stripe/pay",request, { headers: headers })
        .then((response) => {
          this.paying = false;
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCart");
            this.$router.push({
              name: "gracias",
              params: { receipt: response.data.response },
            });
          }
        });
    },
    pay() {
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/cart/conekta/pay", this.$store.state.cart, { headers: headers })
        .then((response) => {
          this.paying = false;
          console.log(response);
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCart");
            this.$router.push({
              name: "gracias",
              params: { receipt: response.data.response },
            });
          }
        });
    },
    payOxxo(){
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/cart/conekta/pay-oxxo", this.$store.state.cart, { headers: headers })
        .then((response) => {
          this.paying = false;
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCart");
            this.axios.get(`/course/payments-by-order/${response.data.response.ticket.receipt}`)
            .then(res=>{
              this.$store.commit('setPaymentReference', null)
              const order = structOrder(res.data)
              this.$store.commit('setPaymentReference', order[0])
              this.$router.push('/payment-reference')
            })
          }
        })
        .catch(e=>{
          this.paying = false;
          this.errorMessage = e.response.data.message
          this.showError = true
          this.showPaying = false;
          setTimeout(()=>{
            this.showError = false
          },2000)
        })
    },
    setDefaultMethod() {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      var req = {
        paymentToken: this.cardSelected.id,
      };
      this.axios
        .post("/user/payment/default", req, { headers: headers })
        .then((response) => {
          console.log(response);
          this.getPaymentMethodList();
        });
    },
    getPaymentMethodList() {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .get("/user/stripe/payment/list", { headers: headers })
        .then((response) => {
          this.loadingPaymentMethods = false;
          this.paymentMethods = response.data.response;
          this.paymentMethods.forEach((element) => {
            if (element.defaultMethod) {
              this.cardSelected = element;
            }
          });
        });
    },
  },
};
</script>
