<template>
  <div style="margin-top: 100px">
    <v-row v-if="$store.state.cartEcommerce.length == 0">
      <v-col cols="12">
        <div style="text-align: center; font-size: 30px">
          No tienes cursos en tu carrito
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$store.state.cartEcommerce.length > 0">
      <v-col cols="12" md="5" offset-md="2">
        <h2>Carrito de compras</h2>
        <div
          v-for="item in $store.state.cartEcommerce"
          v-bind:key="item.id"
          style="margin-top: 30px"
        >
          <v-row>
            <v-col cols="12" md="3">
              <img
                :src="item.images[0].src"
                style="width: 100%; border-radius: 10px"
              />
            </v-col>
            <v-col cols="12" md="6">
              <p>{{ item.name }}</p>
              <p>Cantidad: {{ item.quantity }}</p>
              <p>Precio: $ {{ item.regular_price }}</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                dark
                class="red"
                @click="$store.commit('removeCourseFromEcommerceCart', item)"
                >Eliminar</v-btn
              >
            </v-col>
          </v-row>
          <hr style="margin-top: 10px" />
        </div>
        <v-row>
          <v-col cols="12" md="3"> </v-col>
          <v-col cols="12" md="6">
            <p>Envío a cualquier parte de la República Mexicana</p>
            <p>$120</p>
          </v-col>
        </v-row>
        <p style="font-size: 30px; text-align: right">
          Precio total ${{ price }}
        </p>
      </v-col>
      <v-col cols="12" md="3">
        <h2>Detalles del envio</h2>
        <v-form ref="form">
        <v-text-field
          name="name"
          label="Nombre de quien recibe"
          outlined
          v-model="request.nombre"
          id="id"
          :rules="rules"
        ></v-text-field>
        <v-text-field
          name="name"
          label="Apellido de quien recibe"
          :rules="rules"
          outlined
          v-model="request.apellido"
          id="id"
        ></v-text-field>
        <v-text-field
          name="name"
          label="Código postal"
          :rules="rules"
          v-model="request.codigoPostal"
          outlined
          id="id"
        ></v-text-field>
        <v-text-field
          name="name"
          label="Estado"
          :rules="rules"
          v-model="request.estado"
          outlined
          id="id"
        ></v-text-field>
        <v-text-field
          name="name"
          label="Ciudad"
          :rules="rules"
          v-model="request.ciudad"
          outlined
          id="id"
        ></v-text-field>
        <v-text-field
          name="name"
          label="Dirección completa"
          :rules="rules"
          v-model="request.direccion"
          outlined
          id="id"
        ></v-text-field>
        <v-text-field
          name="name"
          label="Teléfono de contacto"
          :rules="rules"
          v-model="request.telefono"
          outlined
          id="id"
        ></v-text-field>
        <v-text-field
          name="name"
          label="Correo de contacto"
          :rules="rules"
          v-model="request.correo"
          outlined
          id="id"
        ></v-text-field>
        </v-form>
        <h2>Forma de pago</h2>
        <div v-if="!loadingPaymentMethods">
          <v-select
            v-model="cardSelected"
            :items="paymentMethods"
            attach
            outlined
            item-text="maskedNumber"
            return-object
            @change="setDefaultMethod"
            label="Selecciona la tarjeta o crea una nueva"
          ></v-select>
        </div>
        <p>Si tu forma de pago es Paypal, asegúrate de haber llenado el formulario antes de ir a Paypal a realizar tu pago</p>
        <PayPal
          :amount="String(price)"
          currency="MXN"
          style="width: 100%; margin-top: 30px"
          v-on:payment-authorized="paymentAuthorized"
          v-on:payment-completed="paymentCompleted"
          v-on:payment-cancelled="paymentCancelled"
          :client="paypal"
          :button-style="myStyle"
        ></PayPal>
        <v-btn
          elevation="2"
          depressed
          :disabled="!cardSelected" 
          dark
          style="width: 100%; margin-top: 30px"
          color="turquoise"
          @click="pay()"
          >Pagar</v-btn
        >
        <v-btn
          elevation="2"
          style="width: 100%; margin-top: 30px"
          depressed
          dark
          color="turquoise"
          @click="showNewMethod = true"
          >Agregar tarjeta</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog v-model="showPaying" max-width="400">
      <v-card :loading="paying">
        <v-card-text v-if="!paymentError && paying">
          <p style="font-size: 20px; text-align: center; padding-top: 20px">
            Estamos procesando tu pago por favor espera
          </p>
        </v-card-text>
        <v-card-text v-if="paymentError && !paying">
          <p style="font-size: 20px; text-align: center; padding-top: 20px">
            Ocurrió un problema con tu pago, por favor intenta con otra tarjeta.
          </p>
          <v-btn
            dark
            class="purple"
            @click="showPaying = false"
            style="width: 100%"
            >Intentar con otro método</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog overlay fullscreen v-model="showNewMethod">
      <CreditCard :cerrarFormaPago="cerrarFormaPago"></CreditCard>
    </v-dialog>
  </div>
</template>
<script>
import CreditCard from "../components/CreditCard.vue";
import PayPal from 'vue-paypal-checkout'

export default {
  components: {
    CreditCard,
    PayPal
  },
  computed: {
    price() {
      var price = 0;
      this.$store.state.cartEcommerce.forEach(
        (element) =>
        {

          if(element.quantity==null || element.quantity==0){
            element.quantity=1;
          }
          (price = price + parseFloat(element.regular_price) * element.quantity)
        }
      );
      return price + 120;
    },
  },
  mounted() {
    this.getPaymentMethodList();
    this.$root.$on("closeCard", () => {
      this.showNewMethod = false;
      this.getPaymentMethodList();
    });
  },
  data() {
    return {
      rules: [
        v => !!v || 'Este campo es requerido',
      ],
      myStyle: {
          label: 'checkout',
          size:  'responsive',
          shape: 'pill',
          color: 'gold' 
      },
      showNewMethod: false,
      paymentMethodSelected: null,
      paying: true,
      paymentError: false,
      showPaying: false,
      loadingPaymentMethods: true,
      paymentMethods: [],
      cardSelected: null,
      request: {},
      paypal: {
        sandbox: 'AR60eIVybDRMrf-AoIJn-nRKxoPTGa5LjvybJvnRTJH9OQKHTkdxGSo46gRshKtFAmWUm2rdkDQ5Tlp0',
        production: 'AZsdBFpWku8AXIHlXvYndAZ_yr9dh89e6-3j1Ybu7bEBXr0cZigM1OOUoxfY0XUDQXV7GGTTygWqxf1v'
      },
    };
  },
  methods: {
    paymentAuthorized: function (data) {
      console.log(data);
    },
    paymentCompleted: function (data) {
      console.log(data);
      this.payPaypal(data.id);
    },
    paymentCancelled: function (data) {
      console.log(data);
      this.paymentError = true;
    },
    cerrarFormaPago() {
      this.showNewMethod = false;
    },
    payPaypal(orderId) {
      console.log(orderId)
      if(!this.$refs.form.validate()){
        return;
      }
      
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      console.log(headers);
      this.request.products = this.$store.state.cartEcommerce;
      console.log(this.request);
      this.axios
        .post("/store/order/paypal", this.request, { headers: headers })
        .then((response) => {
          this.paying = false;
          console.log(response);
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyEcommerceCart");
            this.$router.push({
              name: "gracias-ecommerce",
              params: { receipt: response.data },
            });
          }
        }).catch(e => {
          if(e.response.status === 401)
            this.$store.dispatch('LogoutException')
        })
    },    
    pay() {
      console.log(this.$refs.form.validate())
      if(!this.$refs.form.validate()){
        return;
      }
      
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.request.products = this.$store.state.cartEcommerce;
      console.log(this.request);
      console.log(this.cardSelected.id);
      this.request.payment_method_id = this.cardSelected.id;
      console.log(this.request);      
      this.axios
        .post("/store/order/stripe", this.request, { headers: headers })
        .then((response) => {
          this.paying = false;
          console.log(response);
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyEcommerceCart");
            this.$router.push({
              name: "gracias-ecommerce",
              params: { receipt: response.data },
            });
          }
        }).catch(e => {
          if(e.response.status === 401)
            this.$store.dispatch('LogoutException')
        })
    },
    setDefaultMethod() {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      var req = {
        paymentToken: this.cardSelected.id,
      };
      this.axios
        .post("/user/payment/default", req, { headers: headers })
        .then((response) => {
          console.log(response);
          this.getPaymentMethodList();
        }).catch(e => {
          if(e.response.status === 401)
            this.$store.dispatch('LogoutException')
        })
    },
    getPaymentMethodList() {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .get("/user/stripe/payment/list", { headers: headers })
        .then((response) => {
          this.loadingPaymentMethods = false;
          this.paymentMethods = response.data.response;
          this.paymentMethods.forEach((element) => {
            if (element.defaultMethod) {
              this.cardSelected = element;
            }
          });
        }).catch(e => {
          if(e.response.status === 401)
            console.log()
            //this.$store.dispatch('LogoutException')
        })
    },
  },
};
</script>
