<template>
    <div style="margin-top: 100px">
        <v-row  v-if="book">
            <v-col md="6" cols="12" sm="12">
                <img :src="book.imageUrl" style="width: 90%; border-radius: 30px" />
            </v-col>
            <v-col md="6" cols="12" sm="12" style="text-align: center;">
                <h2>{{ book.name }}</h2>
                <p v-html="book.description" style="text-align: justify;"></p>
                <v-btn v-if="book.free" dark color="turquoise" @click="abrirUrl(book.url)"
                    style="margin-top:40px;">Descargar libro</v-btn>
                <v-btn v-if="!book.free" dark color="turquoise" @click="abrirUrl(book.url)"
                    style="margin-top:40px;">Ir a la tienda</v-btn>                    
            </v-col>
        </v-row>
    </div>
</template>
<script>

export default {
    
    data() {
        return {
            book: null
        }
    },
    methods: {
        abrirUrl(url) {
            window.open(url, "_blank");
        },
    },
    mounted() {
        console.log(this.$route.params.id)
        this.axios
        .get("/course/book/" + this.$route.params.id)
        .then((response) => {
            this.book = response.data
        });
    },
}
</script>
  
